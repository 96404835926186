.request__complete__wrapper{
  padding: 70px 0 80px;
}
.request__complete__wrapper table tr th, 
.request__complete__wrapper table tr td {
  padding: 16px 30px;
  font-size: 16px;
  letter-spacing: 1.4px;
  line-height: 21px;
  vertical-align: middle;
}
@media (max-width:767.98px){
  .request__complete__wrapper{
      padding: 50px 0 40px;
  } 
  .request__complete__wrapper table tr th, 
  .request__complete__wrapper table tr td {
      padding: 12px 30px;
      font-size: 12px;
      letter-spacing: 1.4px;
      line-height: 16px;
  }
}
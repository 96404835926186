.terms_heading_main {
    color: #000;
    font-family: "NotoSansKR-SemiBold";
    font-size: 35px;
    letter-spacing: -1.75px;
    border-bottom: 1px solid #e1e1e1;
    margin: 80px 0 42px 0;
    padding-bottom: 20px;
}

.terms_sub_heading,
.container-terms p {
    color: #666;
    font-family: "NotoSansKR-Light";
    font-size: 13px;
    letter-spacing: -0.65px;
    line-height: 22px;
    margin: 0 0 25px 0;
}

.terms_sub_heading {
    margin: 0;
    font-weight: bold;
}

.container-terms p ol,
.container-terms p ul {
    padding-left: 17px;
    margin: 0;
}

.container-terms p ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.container-terms p ol li,
.container-terms p ul li {
    color: #666;
    font-family: "NotoSansKR-Light";
    font-size: 13px;
    letter-spacing: -0.65px;
    line-height: 22px;
}
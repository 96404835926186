@font-face {
    font-style: normal;
    font-family: 'NotoSansKR-Regular';
    src: url('./NotoSansKR-Regular.otf');
}

@font-face {
    font-style: normal;
    font-family: 'NotoSansKR-Medium';
    src: url('./NotoSansKR-Medium.otf');
}

@font-face {
    font-style: normal;
    font-family: 'NotoSansKR-Bold';
    src: url('./NotoSansKR-Bold.otf');
}

@font-face {
    font-style: normal;
    font-family: 'NotoSansKR-SemiBold';
    src: url('./NotoSansKR-SemiBold.ttf') format('truetype');
}

@font-face {
    font-style: normal;
    font-family: 'NotoSansKR-ExtraBold';
    src: url('./NotoSansKR-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-style: normal;
    font-family: 'NotoSansKR-Light';
    src: url('./NotoSansKR-Light.ttf') format('truetype');
}
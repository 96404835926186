:root {
  --white: #fff;
  --black: #000;
  --gray: #e2e2e2;
  --gray1: #e1e1e1;
  --yellow: #ff9c00;
  --lightRed: #e9317f;
  --yellowDark: #ff7300;
  --blue: #3c85de;
  --blueDark: #346fd0;
  --orangeGradiant: linear-gradient(to bottom, #ffc500 0%, #ff7300 100%);
  --blueGradiant: linear-gradient(0deg, #3b85de 1.33%, #26bbe3 101.67%);
  --darkOrangeGradiant: linear-gradient(180deg, #ff7f4e 0%, #f03752 100%);
  --grey: #bababa;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

.color-orange {
  color: #ff6600;
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-height: 71vh !important;
  min-height: 670px;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white) !important;
  overflow: inherit !important;
  /* font-family: 'Noto Sans', sans-serif !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h-40px {
  height: 40px !important;
}

.btn-auth {
  width: 100% !important;
  height: 46px;
}

.h-md-46px {
  height: 46px !important;
}

.btn.btn-primary.h-60px {
  height: 60px;
}

/*.btn.btn-primary,*/
/*.btn.btn-primary:hover,*/
/*.btn.btn-primary:focus,*/
/*.btn.btn-primary:active,*/
/*.btn.btn-primary:visited {*/
/*  height: 46px;*/
/*  color: #fff;*/
/*  font-size: 16px;*/
/*  line-height: 35px;*/
/*  font-family: 'NotoSansKR-Bold';*/
/*  background-color: #FF9C00;*/
/*  padding: 0 25px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  border-radius: 5px;*/
/*  border-color: #FF9C00;*/
/*  text-wrap: nowrap;*/
/*}*/

.btn.btn-primary {
  height: 46px;
  color: #fff;
  font-size: 16px;
  line-height: 35px;
  font-family: "NotoSansKR-Bold";
  background-color: #ff9c00;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-color: #ff9c00;
  text-wrap: nowrap;
  white-space: nowrap;
}

.btn.btn-tobble {
  height: 46px;
  color: #fff;
  font-size: 16px;
  line-height: 35px;
  font-family: "NotoSansKR-Bold";
  background-color: #3989de;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-color: #3989de;
  text-wrap: nowrap;
  white-space: nowrap;
}

.container {
  max-width: 1080px !important;
  width: calc(100% - 56px) !important;
}

.navbar-toggler {
  border: none !important;
  background-color: transparent !important;
  padding: 0px !important;
  margin-right: -2px !important;
}

.HeaderSection {
  margin-top: 36px;
}

.pageBreadcrumb .Btn {
  opacity: 0.9;
  font-size: 16px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.8px;
  color: rgba(131, 131, 131, 0.9) !important;
}

.pageBreadcrumb .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  color: rgba(131, 131, 131, 0.9) !important;
  border-top: 0.4em solid !important;
  border-right: 0.4em solid transparent !important;
  border-bottom: 0;
  border-left: 0.4em solid transparent !important;
}

.pageBreadcrumb .btn-group> :not(.btn-check:first-child)+.btn {
  padding: 0px !important;
}

/* 회원가입 placeholder 스타일 설정 */
.custom-placeholder::placeholder {
  color: #ff6347;
  opacity: 1;
}

/* canvas css start */
.desktopHeader .offcanvas-header .btn-close {
  opacity: 1 !important;
  font-size: 20px;
}

.CanvasWrapper h6 {
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -1px;
  text-align: left;
  margin-bottom: 23px;
  color: var(--blueDark);
  font-family: "NotoSansKR-Medium";
}

.OrangeHeading h6 {
  color: var(--lightRed) !important;
}

.CanvasWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}

.canvasLeftSection {
  width: calc(100% - 500px);
  padding-bottom: 60px;
}

.canvasLeftBlock+.canvasLeftBlock {
  margin-top: 27px;
  padding-top: 27px;
  border-top: 1px solid #e1e1e1;
}

.canvasLeftBlockMenu {
  padding: 0;
  list-style: none;
  margin: 0;
}

.canvasLeftBlockMenuItem {
  display: flex;
  align-items: flex-start;
}

.canvasLeftBlockMenuMain {
  width: 96px;
  font-size: 16px;
  font-family: "NotoSansKR-Medium";
  letter-spacing: 0.6px;
  position: relative;
  padding-right: 16px;
  margin-top: 4px;
}

.canvasLeftBlockMenuMainLists {
  width: calc(100% - 10px);
  /*padding-left: 5%;*/
  /* padding-top: 6px; */
  padding-top: 7px;
}

.canvasLeftBlockMenuMainLists ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
}

.canvasLeftBlockMenuMain span {
  position: absolute;
  right: 1px;
  width: 6px;
  top: 7px;
  height: 15px;
}

.canvasLeftBlockMenuMain span img {
  width: 100%;
  object-fit: contain;
  vertical-align: super;
}

.canvasLeftBlockMenuItem+.canvasLeftBlockMenuItem {
  margin-top: 8px;
}

.canvasLeftBlockMenuMainLists ul li {
  font-size: 15px;
  font-family: "NotoSansKR-Medium";
  color: rgba(131, 131, 131, 0.9);
  padding-left: 10px;
  line-height: 15px;
  min-width: 25%;
  margin-bottom: 8px;
  margin-left: 5px;
  /* 왼쪽으로 10px 여백 */
  letter-spacing: 1px;
}

.chk_col4 {
  width: 20%;
}

div.card-dropdown {
  padding: 0;
}

div.card-dropdown>div:first-child {
  padding: 35px 40px 0 40px;
}

div.card-dropdown>div.borderbtm:first-child {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 25px;
}

div.card-dropdown>div:last-child {
  padding-bottom: 35px;
}

div.card-dropdown>div.open:last-child {
  padding-bottom: 30px;
}

.mt-30px {
  margin-top: 30px;
}

.pb-30px {
  padding-bottom: 30px !important;
}

.chkButton .form-check-input {
  margin: 0;
  width: 36px;
  height: 36px;
}

@media screen and (max-width: 1080px) {
  img.interest_img {
    width: 23px;
    height: 19px;
  }

  .card-dropdown>div div {
    border-bottom: unset !important;
  }

  .canvasLeftBlockMenuMainListsHeader {
    margin-right: 10px;
  }
}

@media screen and (max-width: 1080px) {
  .canvasLeftBlockMenuMainLists ul li a {
    color: rgba(131, 131, 131, 0.9);
    text-decoration: none;
    display: block;
    font-size: 14px;
    line-height: 1;
  }
}

.canvasLeftBlockMenuMainLists ul li a {
  color: rgba(131, 131, 131, 0.9);
  text-decoration: none;
  display: block;
}

.headerPadding {
  padding-top: 16px !important;
}

.canvasRightSection {
  width: 500px;
  padding-left: 80px;
}

.canvasRightSection .sliderCard {
  width: 100%;
  height: 250px;
  background-color: #dddddd;
  box-shadow: 5px 8.7px 15px 0 rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -1px;
  color: var(--black);
}

.canvasRightSection .swiper-pagination-bullet-active {
  background-color: var(--yellow) !important;
  height: 14px;
  width: 14px;
}

.canvasRightSection .swiper-pagination-bullet {
  height: 14px;
  width: 14px;
  margin-left: 12px !important;
  background-color: #f9f9f9;
  opacity: 1;
}

.canvasRightSection .swiper-pagination {
  bottom: 12px !important;
}

.canvasRightSection .canvasRightContentBottomDiv {
  margin-top: 41px;
  text-align: center;
}

.canvasRightSection .canvasRightContentBottomDiv h5 {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -1px;
  color: var(--black);
  margin-bottom: 0px;
}

.canvasRightSection .canvasRightContentBottomDiv p {
  opacity: 0.9;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.8px;
  color: rgba(131, 131, 131, 0.9);
  margin-bottom: 0px;
  margin-top: 5px;
}

.canvasRightSection .canvasRightContentBtnDiv {
  margin-top: 26px;
  gap: 10px;
}

.canvasRightSection .canvasRightContentBtnDiv a {
  text-decoration: none !important;
}

.canvasRightSection .canvasRightContentBtnDiv .afterLoginTxt {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -1px;
  color: var(--black);
}

.canvasRightSection .canvasRightContentBtnDiv .yellowBtn img {
  height: 27px;
  width: 27px;
  margin-right: 20px;
}

.canvasRightSection .canvasRightContentBtnDiv .yellowBtn {
  width: 205px;
  height: 49px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: -0.8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background-color: var(--yellow);
  border: none;
  border-radius: 6px;
}

.canvasRightSection .canvasRightContentBtnDiv .yellowBtnBdr {
  width: 205px;
  height: 49px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: -0.8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--yellow);
  background-color: transparent;
  border: 2px solid var(--yellow);
  border-radius: 6px;
}

.canvasRightSection .canvasRightContentBtnBottomDiv {
  margin-top: 20px;
}

.canvasRightSection .canvasRightContentBtnBottomDiv span {
  font-size: 14.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.55;
  letter-spacing: -0.36px;
  color: #656565;
  padding: 0px 22px 0px 23px;
}

.canvasRightSection .canvasRightContentBtnBottomDiv .bdr {
  border-right: 1px solid #e1e1e1;
  margin-left: 10px;
}

.canvasRightSection .canvasRightBottomAfterLoginDiv {
  margin-top: 33px;
  gap: 20px;
}

.canvasRightSection .canvasRightBottomAfterLoginDiv .largeBtnDiv {
  width: 205px;
  padding: 10px;
  border: 2px solid #e1e1e1;
  border-radius: 10px;
}

.canvasRightSection .canvasRightBottomAfterLoginDiv .largeBtnDiv span {
  opacity: 0.9;
  font-size: 16px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.8px;
  color: rgba(102, 102, 102, 0.9);
}

.canvasRightSection .canvasRightBottomAfterLoginDiv .largeBtnDiv img {
  width: 6px;
  height: 11px;
}

.canvasRightSection .canvasRightBottomAfterLoginDiv .largeBtnDiv .numberDIv {
  text-align: end;
  opacity: 0.9;
  font-size: 26px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.65px;
  color: var(--yellow);
  margin-top: 5px;
  font-family: "NotoSansKR-Medium";
}

/* canvas css end */
.desktopHeader .container {
  max-width: 1208px !important;
}

.offcanvas.offcanvas-top {
  /* height: 100vh !important;
  box-shadow: 5px 8.7px 15px 0 rgba(0, 0, 0, 0.03); */
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.headerTopOffcanvas.offcanvas {
  background: transparent !important;
}

.headerTopOffcanvas.offcanvas .desktopHeader {
  background-color: #fff;
}

.headerTopOffcanvas.offcanvas .offcanvas-body {
  background-color: #fff !important;
  flex-grow: inherit;
  overflow: auto; /* overflow-y: auto;*/
   /* 마이넘버로에서 992이상 화면 스크롤 안됨  */
   /* overflow: hidden; */
}

.CustomCheckbox .form-check-input:checked {
  background-color: var(--yellow) !important;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid transparent !important;
}

.CustomCheckbox.chkButton .form-check-input {
  background-color: var(--white) !important;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid transparent !important;
}

.CustomCheckbox.chkButton .form-check-input:checked {
  /* background-color: var(--blue) !important; */
  background: url(../public/img/btn-checkbox-checked.png) no-repeat center;
  background-size: contain;
}

.CustomCheckbox.chkButton+div {
  color: #000;
  letter-spacing: -1.03px;
  font-size: 20.5px;
  line-height: 19.5px;
  font-family: "NotoSansKR-SemiBold";
}

.CustomCheckbox {
  display: flex !important;
}

.customHeader {
  border-bottom: 1px solid #e1e1e1;
  padding: 14px 0 16px !important;
}

.customHeader .header__right__action {
  margin-right: 18px;
}

.customHeader .navbar-toggler-icon {
  background: url(../public/img/top_icon_menu.svg) no-repeat center;
  background-size: auto;
  width: 26px !important;
  height: 20px !important;
  margin-top: 5px;
}

.customHeader .navbar-brand {
  padding: 0;
  width: 144px;
  padding-left: 3px;
  margin-right: 104px;
  margin-top: 3px;
}

.customHeader .navbar-brand img {
  width: 100%;
}

.header_menu-items {
  margin-top: 6px;
  align-items: flex-start;
}

.header_menu_item+.header_menu_item {
  margin-left: 48px;
}

.header_menu_item a {
  font-size: 17px;
  font-family: "NotoSansKR-Regular";
  color: #000;
  text-decoration: none !important;
}

.header_menu_item:last-child {
  margin-left: 36px;
}

.header_menu_child_item a {
  font-size: 14px;
  color: #666;
  text-decoration: none !important;
  letter-spacing: 0.6px;
}

.header_menu_item a.LinkTagActive {
  color: #3c85de;
}

.header_menu_item a.LinkTagActiveBuy {
  color: #f03752;
}

.header_menu_child_item+.header_menu_child_item {
  margin-left: 41px;
}

.header_menu_child {
  padding-left: 39px;
  position: relative;
  margin-top: 2px;
}

.header_menu_child::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 17px;
  background: #c1c1c1;
  left: -1px;
  top: 4px;
}

.CustomCheckbox input[type="radio"].form-check-input:checked {
  background-color: transparent !important;
  position: relative;
  border: 2px solid #e1e1e1 !important;
}

.CustomCheckbox input[type="radio"].form-check-input:checked:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--yellow) !important;
}

.CustomCheckbox .form-check-input {
  border: 1px solid #e1e1e1 !important;
}

.CustomCheckbox input {
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height: 1.2rem;
  width: 1.2rem;
}

.CustomCheckbox input[type="radio"] {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0;
}

.CustomCheckbox label {
  margin-left: 10px;
  margin-top: 0;
}

.CustomCheckbox,
.CustomCheckerBox {
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
}

.CustomCheckerBox input[type="checkbox"] {
  /*width: 36px;*/
  /*height: 36px;*/
  width: 20px;
  height: 20px;

  border: 2px solid #e1e1e1;
}

.CustomCheckerBox .mt-12 {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.CustomCheckerBox .form-check-input {
  margin-top: 0;
}

.CustomCheckerBox .form-check-label {
  margin-left: 10px;
}

.CustomCheckerBox .form-check-label-ml-7 {
  margin-left: 7px;
}

.CustomCheckerBox .form-check-input[type="checkbox"]:checked {
  background-color: var(--yellow) !important;
  border-color: var(--yellow) !important;
}

.notification-setting .BtnDiv {
  margin-top: 35px;
}

.personalSellingPage .cardMainWrapper {
  margin-top: 38px;
}

.LinkTag {
  text-decoration: none !important;
}

.LinkTagActive {
  text-decoration: none;
}

.LinkTagActive a {
  color: var(--blue) !important;
  text-decoration: none !important;
}

.YellowLink {
  text-decoration: none !important;
}

.YellowLinkActive {
  text-decoration: none !important;
}

.YellowLinkActive {
  color: var(--yellowDark) !important;
  text-decoration: none !important;
}

.LinkTagActive a {
  text-decoration: none !important;
}

.LoginLink {
  color: #656565 !important;
  text-decoration: none;
}

.mobileHeader span {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.6;
  letter-spacing: -2px;
  text-align: left;
  color: var(--black);
  margin-left: 15px;
}

/* personalSelling Page css start */
.personalSellingPage .mobileFooterMainWrapper {
  z-index: 99;
  width: 100%;
  bottom: 0;
}

.personalSellingPage .mobileFooterMainWrapper .NewBtnColor {
  background-image: linear-gradient(to bottom,
      #ffc500 0%,
      #ff7300 100%) !important;
}

.personalSellingPage .mobileBottomFooterBlue {
  height: 80px;
  margin: 20px 0 0;
  background: linear-gradient(360deg, white 50%, transparent 56%);
  padding: 10px 0px 10px 0px;
  bottom: 0;
  z-index: 9;
  width: 100%;
}

.container {
  max-width: 1080px !important;
  padding: 0;
  /* width: calc(100% - 56px) !important; */
}

.content-container {
  padding: 85px 0px 100px 0px;
}

.paddingBottom {
  padding-bottom: 100px;
}

.requestFormMain-container {
  padding-top: 70px;
  padding-bottom: 100px;
}

.requestFormMain-container .FormDIv {
  margin-top: 45px;
}

@media screen and (max-width: 992px) {
  .btn.btn-auth {
    height: 46px !important;
  }

  .requestFormMain-container .FormDIv {
    margin-top: 15px;
  }
}

.requestFormMain-container .FormDIv tr {
  height: 54px;
}

.requestFormMain-container .FormDIv .paddingLeft {
  padding-left: 54px;
}

.requestFormMain-container .SecondSection {
  margin-top: 25px;
}

@media screen and (max-width: 992px) {
  .requestFormMain-container .SecondSection {
    margin-top: 15px;
  }

  .requestFormMain-container .SecondSection2 {
    margin-top: 15px;
  }
}

.requestCompleteMainDiv {
  padding-top: 70px;
  padding-bottom: 100px;
}

.buyingFormDetails {
  padding-top: 126px;
  padding-bottom: 100px;
}

.buyingFormDetails .FormDiv {
  margin-top: 45px;
}

.buyingFormDetails .FormDiv .leftMargin {
  padding-left: 42px !important;
  display: flex;
  align-items: center;
}

.buyingFormDetails .FormDiv .newLabel label {
  margin-top: 0px !important;
}

.fileBtn {
  width: 120px;
  height: 46px;
  background: #ff9c00;
  color: #fff;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-wrap: nowrap;
  white-space: nowrap;
  cursor: pointer;
}

/*   ===================Start Pages>MyPage>Main=================== */
.mypage_wrapper .navtab_header {
  width: 100%;
  height: 50px;
  background-image: linear-gradient(to left, #ffa600, #ff7200);
}

.mypage_wrapper .navtab_header .navtab_inner {
  column-gap: 128px;
}

.mypage_wrapper .navtab_header a {
  color: var(--white);
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.8px;
}

.mypage_wrapper .banner_part {
  background: #fbebc8;
  width: 100%;
  height: 200px;
  margin: 54px 0 80px 0px;
}

.mypage_wrapper .banner_part .banner_inner_part {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 94px;
}

.mypage_wrapper .banner_part .banner_inner_part .banner_icon_box .circle_box {
  width: 115px;
  height: 115px;
  background: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 38px;
  color: var(--yellow);
  /* margin: 0px 94px; */
}

.mypage_wrapper .banner_part .banner_inner_part .banner_icon_box .circle_tittle {
  text-align: center;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  opacity: 0.9;
  line-height: 1.5;
  color: rgba(51, 51, 51, 0.9);
  padding-top: 11px;
  text-wrap: nowrap;
  white-space: nowrap;
}

.mypage_wrapper .card_part.mt_top {
  margin-top: 58px;
  margin-bottom: 70px;
}

.ms-md-15px {
  margin-left: 15px;
}

.mypage_wrapper .card_part h2 {
  font-size: 24px;
  font-family: "NotoSansKR-Medium";
  line-height: 0.69;
  letter-spacing: 0.6px;
  text-align: left;
  color: var(--black);
}

.mypage_wrapper .card_part .card_box {
  width: 100%;
  background: var(--white);
  padding: 15px 0px 39px 0px;
  box-shadow: 4px 6.9px 27px 0 rgba(45, 70, 98, 0.15);
  border-radius: 18px;
  margin-top: 39px;
  margin-bottom: 25px;
}

.mypage_wrapper .card_part .card_box .top_part .breadcrumb ul {
  list-style: none;
  display: flex;
  margin-bottom: 0;
  column-gap: 27px;
  padding: 0;
}

.mypage_wrapper .card_part .card_box .top_part .breadcrumb ul li {
  position: relative;
  color: rgba(0, 0, 0, 0.9) !important;
  font-size: 18px;
  opacity: 0.9;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.6px;
}

.mypage_wrapper .card_part .card_box .top_part .breadcrumb ul .blueLi {
  color: rgba(27, 111, 207, 0.9) !important;
}

.mypage_wrapper .card_part .card_box .top_part .breadcrumb ul .RedLi {
  color: rgba(233, 71, 94, 0.9) !important;
}

.mypage_wrapper .card_part .card_box .top_part {
  border-bottom: 1px solid rgba(45, 70, 98, 0.15);
  padding: 1px 47px;
}

.mypage_wrapper .card_part .card_box .top_part .breadcrumb ul li::after {
  content: ">";
  position: absolute;
  top: 2px;
  right: -18px;
  color: black;
}

.mypage_wrapper .card_part .card_box .top_part .breadcrumb ul li:last-child::after {
  content: "";
}

.mypage_wrapper .card_part .card_box .bottom_part {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 37px 44px 0px 44px;
}

.mypage_wrapper .card_part .card_box .bottom_part .left_part {
  width: 52%;
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(45, 70, 98, 0.15);
}

.card_part .bottom_part .left_part .inner_left_part {
  max-width: 152px;
  display: flex;
  column-gap: 20px;
}

.card_part .bottom_part .left_part .part_top_car {
  max-width: 152px;
  line-height: 2;
  color: rgba(146, 82, 180, 0.9);
}

.card_part .bottom_part .left_part .inner_left_part .number {
  opacity: 0.9;
  font-size: 35.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.55;
  letter-spacing: -1.78px;
  text-align: left;
  color: var(--color);
}

.card_part .bottom_part .left_part .inner_left_part .value {
  color: rgba(131, 131, 131, 0.9);
}

/*  */
.mypage_wrapper .card_part .card_box .bottom_part .left_part {
  width: 60%;
}

.card_part .bottom_part .left_part .inner_left_part {
  margin-left: 0px;
}

.card_part .bottom_part .left_part .inner_left_part .part_top {
  display: flex;
  gap: 21px;
}

.card_part .bottom_part .left_part .inner_left_part .part_bottom {
  margin-top: 10px;
  display: flex;
  gap: 50px;
}


/* 240902 */
.mypage_wrapper .card_part .card_box .content_part {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 37px 44px 0px 0px;
}

.mypage_wrapper .card_part .card_box .content_part .left_part {
  width: 70%;
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(45, 70, 98, 0.15);
}

.card_part .content_part .left_part .inner_part {
  margin-left: 66px;
}
.card_part .content_part .left_part .inner_part .part_top {
  display: flex;
  gap: 50px;
  justify-content: space-between;
}
.card_part .content_part .left_part .inner_part .part_top .left_part_value {
  display: flex;
  gap: 21px;
  align-items: center;
}
.card_part .content_part .left_part .inner_part .part_top .right_part_value {
  display: flex;
  gap: 21px;
  align-items: center;
}

.card_part .content_part .left_part .inner_part .part_bottom {
  margin-top: 10px;
  display: flex;
  gap: 50px;
  justify-content: space-between;
}

.card_part .content_part .left_part .inner_part .part_bottom .left_part_value {
  display: flex;
  gap: 21px;
  align-items: center;
}
.card_part .content_part .left_part .inner_part .part_bottom .right_part_value {
  display: flex;
  gap: 21px;
  align-items: center;
}

.mypage_wrapper .card_part .card_box .content_part .left_part .inner_left_part .first_value {
  opacity: 0.9;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.6px;
  color: rgba(131, 131, 131, 0.9);
}

.mypage_wrapper .card_part .card_box .content_part .left_part .inner_left_part .second_value {
  opacity: 0.9;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.75px;
  color: rgba(68, 68, 68, 0.9);
}
.mypage_wrapper .card_part .card_box .content_part .right_part {
  width: 40%;
  padding-left: 39px; 
}
.mypage_wrapper .card_part .card_box .content_part .right_part .inner_right_part {
  width: calc(100% - 112px);
}

.mypage_wrapper .card_part .card_box .content_part .right_part .inner_right_part button {
  width: 100%;
  background-color: #ff9e2f;
  color: var(--white);
  border: none;
  outline: none;
  font-size: 20.5px;
  letter-spacing: 0.6px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  max-width: 273px;
  height: 41px;
  border-radius: 6px;
}
/* .mypage_wrapper .card_part .card_box .content_part .right_part {
  display: flex;
  color: rgba(51, 51, 51, 0.9);
  align-items: center;
  padding-left: 39px; 
} */

.mypage_wrapper .card_part .card_box .content_part .right_part .inner_left_part {
  width: 212px;
  display: flex;
  gap: 16px;
  padding-left: 39px;
  align-items: center;
}

.mypage_wrapper .card_part .card_box .content_part .right_part .inner_left_part .first_value {
  opacity: 0.9;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.6px;
  color: rgba(131, 131, 131, 0.9);
}

.mypage_wrapper .card_part .card_box .content_part .right_part .inner_left_part .second_value {
  opacity: 0.9;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.75px;
  color: rgba(68, 68, 68, 0.9);
}
/* 240902 end */

.mypage_wrapper .card_part .card_box .bottom_part .left_part .inner_left_part .first_value {
  opacity: 0.9;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.6px;
  color: rgba(131, 131, 131, 0.9);
}

.mypage_wrapper .card_part .card_box .bottom_part .left_part .inner_left_part .second_value {
  opacity: 0.9;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.75px;
  color: rgba(68, 68, 68, 0.9);
}
.card_part .bottom_part .left_part .inner_right_part .part_top .left_part_value {
  display: flex;
  gap: 21px;
}

.mypage_wrapper .card_part .card_box .bottom_part .right_part {
  width: 49%;
}

.card_part .bottom_part .left_part .inner_right_part {
  margin-left: 66px;
}

.card_part .bottom_part .left_part .inner_right_part .part_top {
  display: flex;
  gap: 50px;
}

.card_part .bottom_part .left_part .inner_right_part .part_top .right_part_value {
  display: flex;
  gap: 21px;
}

.card_part .bottom_part .left_part .inner_right_part .part_bottom {
  margin-top: 10px;
  display: flex;
  gap: 50px;
}

.card_part .bottom_part .left_part .inner_right_part .part_bottom .right_part_value {
  display: flex;
  gap: 21px;
  align-items: center;
}
/* start */
.card_part .bottom_part .left_part .inner_right_part .part_bottom .left_part_value {
  display: flex;
  gap: 21px;
  align-items: center;
}
.mypage_wrapper .left_part_value .first_value {
  color: rgba(131, 131, 131, 0.9);
  opacity: 0.9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: -0.8px;
  text-wrap: nowrap;
  white-space: nowrap;
}
/* end */
.mypage_wrapper .right_part_value .first_value {
  color: rgba(131, 131, 131, 0.9);
  opacity: 0.9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: -0.8px;
  text-wrap: nowrap;
  white-space: nowrap;
}

.mypage_wrapper .card_part .card_box .bottom_part .right_part {
  display: flex;
  color: rgba(51, 51, 51, 0.9);
  align-items: center;
}

.mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_left_part {
  width: 212px;
  display: flex;
  gap: 16px;
  padding-left: 39px;
  align-items: center;
}

.mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_left_part .first_value {
  opacity: 0.9;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.6px;
  color: rgba(131, 131, 131, 0.9);
}

.mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_left_part .second_value {
  opacity: 0.9;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.75px;
  color: rgba(68, 68, 68, 0.9);
}

.card_part .bottom_part .left_part .inner_right_part .part_top .right_part_value .second_value {
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.8px;
  text-wrap: nowrap;
  white-space: nowrap;
}

.mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_right_part {
  width: calc(100% - 212px);
}

.mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_right_part button {
  width: 100%;
  background-color: #ff9e2f;
  color: var(--white);
  border: none;
  outline: none;
  font-size: 20.5px;
  letter-spacing: 0.6px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  max-width: 273px;
  height: 41px;
  border-radius: 6px;
}

/*   =====================End Pages>MyPage>Main=================== */

/*   =====================Start Pages>MyPage>TabBar=================== */
.parentTab_wrapper .parent_tab {
  width: 100%;
  height: 50px;
  background-image: linear-gradient(to left, #ffa600, #ff7200);
}

.parentTab_wrapper .parent_tab .main_tab {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 129px;
}

.parentTab_wrapper .parent_tab .main_tab .nav_link {
  text-decoration: none;
  font-size: 16px;
  font-family: "NotoSansKR-bold";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.3px;
  text-align: left;
  color: var(--white);
  position: relative;
}

.parentTab_wrapper .parent_tab .main_tab .nav_link.active::after {
  content: "";
  position: absolute;
  bottom: -13px;
  left: 0px;
  height: 4px;
  width: 100%;
  background: var(--white);
}

.parentTab_wrapper .subTab_wrapper {
  box-shadow: 5px 8.7px 15px 0 rgba(0, 0, 0, 0.03);
}

.parentTab_wrapper .sub_main_tab {
  display: flex;
  column-gap: 20px;
  margin: 8px 0px;
}

.parentTab_wrapper .sub_main_tab .navsub_tab {
  width: 150px;
  height: 38px;
  display: flex;
  opacity: 0.9;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: "NotoSansKR-Regular";
  line-height: 1.6;
  text-align: left;
  vertical-align: middle;
  border: 1px solid rgba(163, 163, 163, 0.9);
  color: rgba(163, 163, 163, 0.9);
  border-radius: 20px;
}

.parentTab_wrapper .sub_main_tab .navsub_tab.active {
  background-color: #ff7200;
  color: var(--white);
  border: 1px solid transparent;
}

/*   =====================End Pages>MyPage>TabBar===================== */

/* ======================Start Pages>MyPage>RequestForTransiction==================== */
.request-transiction {
  padding: 80px 0px 100px 0px;
}

.main-heading h1 {
  font-size: 35px;
  letter-spacing: 2.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.69;
  color: var(--black);
}

.request-information {
  margin: 18px 0px;
  padding: 32px 0px;
  border-top: 1px solid var(--gray);
  /* border-bottom: 1px solid var(--gray); */
}

.request-information-border-top-none {
  border-top: none !important;
  padding: 16px 0px !important;
}

.request-information.second {
  border: none;
  padding-bottom: 0;
}

.request-information .breadcrumb {
  margin: 0;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.request-information .breadcrumb .date-part {
  display: flex;
  column-gap: 20px;
}

.request-information .breadcrumb .date-part p {
  margin: 0;
  color: rgba(131, 131, 131, 0.9);
  opacity: 0.9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.8px;
}

.request-information .breadcrumb ul {
  list-style: none;
  display: flex;
  margin-bottom: 0;
  column-gap: 35px;
  padding: 0;
}

.request-information .breadcrumb ul li {
  position: relative;
  color: var(--color);
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.31;
  letter-spacing: 0.6px;
  opacity: 0.9;
}

.request-information .breadcrumb ul li::after {
  content: ">";
  position: absolute;
  top: 0;
  right: -25px;
  color: black;
}

.request-information .breadcrumb ul li:last-child::after {
  content: "";
}

.request-information .approvel-top-section {
  background: #fdf5e1;
  padding: 30px 0px 30px 0px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 132px;
}

.request-information .approvel-top-section .icons-part .icon-title {
  padding-top: 16px;
  color: rgba(131, 131, 131, 0.9);
  opacity: 0.9;
  font-size: 18px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -1.35px;
}

.request-information .approvel-top-section .icons-part .icon-title.active {
  color: var(--black);
}

.request-information .approvel-top-section .icons-part .icons {
  box-sizing: border-box;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  position: relative;
}

.request-information .approvel-top-section .icons-part .icons::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #ffad00;
  top: 50%;
  left: -66px;
}

.request-information .approvel-top-section .icons-part:first-child .icons::after {
  background-color: transparent;
}

.request-information .approvel-top-section .icons-part .icons::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #ffad00;
  top: 50%;
  left: 66px;
}

.request-information .approvel-top-section .icons-part:last-child .icons::before {
  background-color: transparent;
}

.request-information .approvel-bottom-section {
  background-color: #fbebc8;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding: 20px 0px 20px 0px;
}

.approvel-bottom-section button {
  margin-top: 18px;
  border: none;
  outline: none;
  background-color: transparent;
  color: rgba(255, 84, 0, 0.9);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
}

.request-information .approvel-bottom-section span {
  color: rgba(150, 78, 1, 0.9);
  opacity: 0.9;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.6px;
  text-align: center;
}

table.table tr td {
  vertical-align: middle;
}

.request-information .table-part .sub-heading h3 {
  font-size: 24px;
  font-size: 24px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  line-height: 0.69;
  letter-spacing: 0.6px;
  padding: 46px 0px 25px 0px;
}

.request-information .table-part .table>tbody {
  border-top: 2px solid var(--gray1);
  border-bottom: 2px solid var(--gray1);
}

.request-information .table-part .table td.grey {
  background-color: #f5f5f5;
  color: #888888;
  border-right: 1px solid var(--gray);
  text-align: center;
  padding: 0;
  vertical-align: middle;
}

.request-information .table-part .table tbody tr td.grey:not(:first-child) {
  border-left: 1px solid var(--gray);
}

.border-left-color {
  border-left: 1px solid var(--gray);
}

.request-information .table-part .Newpadding h3 {
  padding: 26px 0px 25px 0px !important;
}

.request-information .table-part .table td {
  padding-left: 36px;
  line-height: 2.2;
}

.request-information .table-part .table td span {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: -1.1px;
  font-size: 22px;
  font-weight: bold;
  color: #4e92e1;
}

.request-information .button-wrapper {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  padding-top: 27px;
}

.request-information .button-wrapper button {
  width: 300px;
  height: 60px;
  vertical-align: middle;
  border-radius: 6px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: -1px;
  text-align: center;
  color: var(--white);
  background-color: var(--bg-color);
  border: none;
  outline: none;
}

/* ======================End Pages>MyPage>RequestForTransiction==================== */

/* ======================Start Pages>MyPage>MyListing==================== */
.my-listing .request-information .approvel-top-section {
  background: #fdf5e1;
  padding: 31px 0px 32px 0px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 92px;
}

/* ======================End Pages>MyPage>MyListing====================== */

/* ======================Start Pages>MyPage>CustomerTab==================*/
.customer_tab {
  padding: 90px 0px 86px 0px;
}

@media screen and (max-width: 768px) {
  .customer_tab {
    margin-top: 50px;
  }

  .offcanvas,
  .offcanvas-lg,
  .offcanvas-md,
  .offcanvas-sm,
  .offcanvas-xl,
  .offcanvas-xxl {
    min-height: unset;
  }
}

.customer_tab .tab-section {
  margin-top: 49px;
  display: flex;
  width: 100%;
  border: 1px solid var(--gray);
}

.customer_tab .tab-section a {
  width: 33.6%;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border-right: 1px solid var(--gray);
  font-size: 21px;
  color: #999;
  padding: 17px 0px 17px 0px;
}

.customer_tab .tab-section a:last-child {
  border-right: none;
}

.tab-section a.active {
  background: #ff9e2f;
  color: var(--white);
}

.customer_tab .customer-service {
  padding-top: 40px;
}

.customer_tab .customer-service .accordion {
  --bs-accordion-bg: transparent !important;
  --bs-accordion-border-radius: 0 !important;
  --bs-accordion-border-color: none !important;
}

.customer_tab .customer-service .accordion-button {
  padding: 23px 30px !important;
}

.accordion-button:not(.collapsed) {
  color: var(--black) !important;
  background-color: transparent !important;
  box-shadow: 0 !important;
}

.customer_tab .customer-service .accordion .accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.customer_tab .customer-service .accordion .accordion-item:first-child {
  border-top: 1px solid var(--gray);
}

.customer_tab .customer-service .accordion .accordion-item {
  border-bottom: 1px solid var(--gray);
}

.customer_tab .customer-service .accordion-button .btn-text {
  opacity: 0.9;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.8px;
  color: #000;
  font-weight: 600;
}

.customer_tab .customer-service .accordion-button.collapsed .btn-text {
  color: rgba(0, 0, 0, 0.9);
  font-weight: normal;
}

.customer_tab .customer-service .accordion .accordion-item .btn-text-active {
  color: #000 !important;
  font-family: "NotoSansKR-Medium";
}

.customer_tab .customer-service .accordion-button .btn-date {
  padding-top: 25px;
  opacity: 0.9;
  /* font-family: 'Arial Bold'; */
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.7px;
  text-align: left;
  color: rgba(136, 136, 136, 0.9);
}

.customer_tab .customer-service .accordion-collapse .under-accordian {
  background: #f5f5f5;
  padding: 30px;
}

.customer_tab .customer-service .accordion-collapse p {
  opacity: 0.9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.8px;
  text-align: left;
  color: rgba(68, 68, 68, 0.9);
}

.qna-service {
  padding-top: 40px;
}

.qna-service .accordion {
  --bs-accordion-bg: transparent !important;
  --bs-accordion-border-radius: 0 !important;
  --bs-accordion-border-color: none !important;
}

.customer-service .accordion-button {
  padding: 23px 30px !important;
}

.accordion-button:not(.collapsed) {
  color: var(--black) !important;
  background-color: transparent !important;
  box-shadow: 0 !important;
}

.qna-service .accordion .accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.qna-service .accordion .accordion-item:first-child {
  border-top: 1px solid var(--gray);
}

.qna-service .accordion .accordion-item {
  border-bottom: 1px solid var(--gray);
}

.qna-service .accordion-button .btn-text {
  opacity: 0.9;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.8px;
  color: #000;
  font-weight: 600;
}

.qna-service .accordion-button.collapsed .btn-text {
  color: rgba(0, 0, 0, 0.9);
  font-weight: normal;
}

.qna-service .accordion .accordion-item .btn-text-active {
  color: #000 !important;
  font-family: "NotoSansKR-Medium";
}

.qna-service .accordion-button .btn-date {
  padding-top: 25px;
  opacity: 0.9;
  /* font-family: 'Arial Bold'; */
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.7px;
  text-align: left;
  color: rgba(136, 136, 136, 0.9);
}

.qna-service .accordion-collapse .under-accordian {
  background: #f5f5f5;
  padding: 30px;
}

.qna-service .accordion-collapse p {
  opacity: 0.9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.8px;
  text-align: left;
  color: rgba(68, 68, 68, 0.9);
}

/* ======================End Pages>MyPage>CustomerTab==================*/

/* ======================Start Pages> Customer Center > QA================*/
.question_answer {
  padding: 43px 0px 43px 0px;
}

.question_answer .sub_title p {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.9px;
  text-align: left;
  color: #555;
  margin-bottom: 19px;
}

.question_answer .sub_title p span {
  font-size: 14px;
  letter-spacing: -0.7px;
  color: #999;
}

.question_answer .subject input {
  padding: 12px 21px 12px 20px;
  border: solid 2px var(--gray1);
  outline: none;
  border-radius: 5px;
}

.question_answer .subject input::placeholder {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.64;
  letter-spacing: -0.35px;
  color: #c1c1c1;
}

.question_answer .subject {
  position: relative;
  margin-bottom: 25px;
}

.question_answer .subject input[type="text"]:focus {
  outline: none;
  border: 2px solid var(--gray1);
}

.question_answer .subject .title {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.64;
  letter-spacing: -0.35px;
  text-align: right;
  color: #b3b3b3;
}

.question_answer .detail {
  position: relative;
  margin-bottom: 29px;
}

.question_answer .detail .title {
  position: absolute;
  right: 18px;
  bottom: 14px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.64;
  letter-spacing: -0.35px;
  text-align: right;
  color: #b3b3b3;
}

.question_answer .detail textarea {
  border: solid 2px var(--gray1);
  border-radius: 5px;
  padding: 12px 21px 12px 20px;
  resize: none;
  height: 260px;
}

.question_answer .detail textarea:focus {
  outline: none;
}

.question_answer .detail textarea::placeholder {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.64;
  letter-spacing: -0.35px;
  text-align: left;
  color: #c1c1c1;
}

.attached_file {
  padding: 12px 21px 12px 20px;
  border: solid 2px var(--gray1);
  position: relative;
  border-radius: 6px;
  width: 100%;
}

.attached_file .plus {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  z-index: 1;
}

.attached_file input[type="file"] {
  opacity: 0;
  height: 19px;
}

.attached_file .attachment {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  left: 15px;
  cursor: pointer;
}

.attached_file .title {
  /*padding-left: 32px;*/
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.35px;
  text-align: left;
  color: #888;
}

.question_answer .main-btn {
  margin-top: 50px;
}

.question_answer .main-btn button {
  width: 290px;
  height: 60px;
  background-color: var(--yellow);
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 20.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.71;
  letter-spacing: -1.03px;
  color: #fff;
}

.question_answer .qa-label {
  padding-top: 51px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: -0.35px;
  text-align: left;
  color: #666;
}

.question_answer .qa-label span {
  color: var(--yellow);
}

/* ======================End Pages> Customer Center > QA==================*/
.personalSellingPage .mobileBottomFooterBlue::before,
.personalSellingPage .mobileBottomFooterBlue::after {
  content: "";
  background-color: var(--white);
  display: block;
  width: 50%;
  position: absolute;
  height: 62px;
  bottom: 0;
  z-index: -1;
  box-shadow: 0px -10px 15px 0 rgba(0, 0, 0, 0.03);
}

.personalSellingPage .mobileBottomFooterBlue::before {
  transform: skewX(15deg);
  left: -30px;
  border-top-right-radius: 20px;
}

.personalSellingPage .mobileBottomFooterBlue::after {
  right: -30px;
  left: auto;
  transform: skewX(-15deg);
  border-top-left-radius: 20px;
}

.personalSellingPage .mobileBottomFooterBlue__wrapper {
  width: 100%;
  padding: 0 30px !important;
  align-items: flex-end;
}

.personalSellingPage .mobileBottomFooterBlue button {
  height: 66px;
  width: 66px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.3);
  /* background: linear-gradient(0deg, #3B85DE 1.33%, #26BBE3 101.67%); */
  background: linear-gradient(0deg,
      var(--subColor) 1.33%,
      var(--color) 101.67%);
  border: none;
  margin-bottom: -2px;
}

.personalBuyingPage .mobileBottomFooterBlue button {
  background: linear-gradient(180deg, #ff7f4e 0%, #f03752 100%);
}

.mobileBottomFooterBlue__menu,
.mobileBottomFooterBlue__menu>a {
  min-width: 40px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

/* .mobileBottomFooterBlue__menu>a:hover p, */
/* .mobileBottomFooterBlue__menu>a:focus p, */
.mobileBottomFooterBlue__menu>a:active p {
  /* .mobileBottomFooterBlue__menu>a:visited p { */
  color: var(--yellow);
}

.mobileBottomFooterBlue__menu>a>p.active {
  color: var(--yellow);
}

.mobileBottomFooterBlue__menu>a.menu-view-price {
  background: url(../public/img/ficon_chart.svg) no-repeat center;
  background-size: 20px;
  background-position-x: center;
  background-position-y: top;
}

.mobileBottomFooterBlue__menu>a.menu-view-price.active {
  background: url(../public/img/ficon_chart_on.svg) no-repeat center;
  background-size: 20px;
  background-position-x: center;
  background-position-y: top;
}

/* .mobileBottomFooterBlue__menu>a.menu-view-price:hover, */
/* .mobileBottomFooterBlue__menu>a.menu-view-price:focus, */
.mobileBottomFooterBlue__menu>a.menu-view-price:active {
  /* .mobileBottomFooterBlue__menu>a.menu-view-price:visited { */
  background: url(../public/img/ficon_chart_on.svg) no-repeat center;
  background-size: 20px;
  background-position-x: center;
  background-position-y: top;
}

.mobileBottomFooterBlue__menu>a.menu-information {
  background: url(../public/img/ficon_guide.svg) no-repeat center;
  background-size: 20px;
  background-position-x: center;
  background-position-y: top;
}

.mobileBottomFooterBlue__menu>a.menu-information.active {
  background: url(../public/img/ficon_guide_on.svg) no-repeat center;
  background-size: 20px;
  background-position-x: center;
  background-position-y: top;
}

/* .mobileBottomFooterBlue__menu>a.menu-information:hover, */
/* .mobileBottomFooterBlue__menu>a.menu-information:focus, */
.mobileBottomFooterBlue__menu>a.menu-information:active {
  /* .mobileBottomFooterBlue__menu>a.menu-information:visited { */
  background: url(../public/img/ficon_guide_on.svg) no-repeat center;
  background-size: 20px;
  background-position-x: center;
  background-position-y: top;
}

.mobileBottomFooterBlue__menu>a.menu-announcement {
  background: url(../public/img/ficon_customer.svg) no-repeat center;
  background-size: 20px;
  background-position-x: center;
  background-position-y: top;
}

.mobileBottomFooterBlue__menu>a.menu-announcement.active {
  background: url(../public/img/ficon_customer_on.svg) no-repeat center;
  background-size: 20px;
  background-position-x: center;
  background-position-y: top;
}

/* .mobileBottomFooterBlue__menu>a.menu-announcement:hover, */
/* .mobileBottomFooterBlue__menu>a.menu-announcement:focus, */
.mobileBottomFooterBlue__menu>a.menu-announcement:active {
  /* .mobileBottomFooterBlue__menu>a.menu-announcement:visited { */
  background: url(../public/img/ficon_customer_on.svg) no-repeat center;
  background-size: 20px;
  background-position-x: center;
  background-position-y: top;
}

.mobileBottomFooterBlue__menu>a.menu-mypage {
  background: url(../public/img/ficon_mypage.svg) no-repeat center;
  background-size: 20px;
  background-position-x: center;
  background-position-y: top;
}

.mobileBottomFooterBlue__menu>a.menu-mypage.active {
  background: url(../public/img/ficon_mypage_on.svg) no-repeat center;
  background-size: 20px;
  background-position-x: center;
  background-position-y: top;
}

/* .mobileBottomFooterBlue__menu>a.menu-mypage, */
/* .mobileBottomFooterBlue__menu>a.menu-mypage:focus, */
.mobileBottomFooterBlue__menu>a.menu-mypage:active {
  /* .mobileBottomFooterBlue__menu>a.menu-mypage:visited { */
  background: url(../public/img/ficon_mypage_on.svg) no-repeat center;
  background-size: 20px;
  background-position-x: center;
  background-position-y: top;
}

.personalSellingPage .mobileBottomFooterBlue img {
  width: 20px;
  height: 30px;
}

.personalSellingPage .mobileBottomFooterBlue p {
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  text-align: center;
  color: #777;
  margin-bottom: 0px;
}

.personalSellingPage .mobileBottomFooterBlue a {
  text-decoration: none !important;
}

.personalSellingPage .mobileBottomFooterBlue button p {
  font-size: 15px;
  letter-spacing: -1px;
  /* margin-bottom: -10px; */
  color: var(--white);
  font-weight: bold;
}

.personalSellingPage .mobileBottomFooterBlue button span {
  font-size: 10px;
  letter-spacing: -1px;
  color: var(--white);
  font-weight: bold;
}

.personalBuyingPage .cardMainWrapper {
  margin-top: 36px;
}

/* personalSelling Page css end */


/* report css start */
.report .card_part {
  margin-top: 15px;
}

.report .intrestCard {
  margin-top: 1px !important;
}

.report .card_part .card_box .bottom_part {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 37px 44px 0px 44px;
}
/* report css end */

/* notification-information css start */
.notification-information-main .cardSection {
  padding: 24px 29px 19px 30px;
  box-shadow: 4px 6.9px 27px 0 rgba(45, 70, 98, 0.15);
  border-radius: 18px;
  margin-top: 10px;
}

.notification-information-main .cardDIv {
  margin-top: 20px;
}

.font16 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 0px;
  opacity: 0.9;
}

.notification-information-main .cardSection {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: -0.7px;
  color: rgba(136, 136, 136, 0.9);
  margin-top: 10px;
}

.notification-setting .checkbox-div {
  padding-left: 20px;
}

.notification-setting .checkbox-div input {
  height: 36px;
  width: 36px;
  border: 2px solid var(--gray1) !important;
  margin-top: 2px !important;
}

.notification-setting .checkbox-div label {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 15px;
}

.notification-setting .checkbox-div .form-check-inline {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.notification-setting .checkbox-div .checkBoxWidth {
  /* width: 20%; */
}

.notification-setting .table>tbody {
  border-top: 2px solid var(--gray);
  border-bottom: 2px solid var(--gray);
}

.notification-setting .table-bordered> :not(caption)>*>* {
  border-right: none;
  border-left: none;
}

.number-interest .card_part {
  margin-top: 15px;
}

.number-interest .intrestCard {
  margin-top: 1px !important;
}

/* notification-information css end */

/* QA css start */
.QAMainDiv {
  padding-bottom: 221px;
}

.QAMainDiv .QADiv {
  margin-top: 16px;
}

.QAMainDiv .QADiv .borderDIv {
  opacity: 0.9;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.6px;
  line-height: 1.75;
  color: rgba(0, 0, 0, 0.9);
  padding-top: 40px;
  padding-bottom: 26px;
  padding-left: 34px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.QAMainDiv .QADiv .QADivSec {
  padding-left: 34px;
  padding-top: 25px;
}

.QAMainDiv .QADiv .QADivSec p {
  opacity: 0.9;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.9);
}

.QAMainDiv .QADiv .QADivSec span {
  opacity: 0.9;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 13px !important;
  display: block;
  letter-spacing: 0.6px;
}

.QAMainDiv .QADiv .QADivSec .dateTxt {
  opacity: 0.9;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  margin-top: 17px;
  letter-spacing: -0.4px;
  color: rgba(136, 136, 136, 0.9);
}

/* .QAMainDiv .QADivBgGray {
  background-color: #f5f5f5;
  padding-left: 34px;
  padding-top: 26px;
  padding-bottom: 24px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 24px;
  position: relative;
} */

.QAMainDiv .QADivBgGray {
  background-color: #f5f5f5;
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 26px;
  padding-bottom: 24px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 24px;
  position: relative;
  /* 긴 단어가 줄바꿈 되도록 처리 */
  overflow-wrap: break-word;
  /* 구형 브라우저를 위한 대체 속성 */
  /* word-wrap: break-word; */

  /* 연속된 개행을 하나로 인식하지 않고 개별적으로 인식함. 
  개행 없이 긴 문자열의 경우 자동으로 줄바꿈 함. pre-wrap이 아닌 pre의 경우 문자열이 줄바꿈되지 않고 뚫고 나옴 */
  white-space: pre-wrap;
}

.QAMainDiv .QADivBgGray .iconLineDiv span p {
  font-weight: normal;
}

.QAMainDiv .QADivBgGray .iconLine {
  height: 2px;
  width: 18px;
  background-color: #000;
  display: block;
  position: absolute;
  top: 41px;
}

.QAMainDiv .QADivBgGray .iconLineDiv {
  padding-left: 30px;
}

.QAMainDiv .QADivBgGray .iconLineDiv p {
  opacity: 0.9;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0.6px;
  font-style: normal;
  color: rgba(0, 0, 0, 0.9);
}

.QAMainDiv .QADivBgGray .iconLineDiv span {
  opacity: 0.9;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 16px;
  display: block;
}

.QAMainDiv .QADivBgGray .iconLineDiv .dateTxt {
  opacity: 0.9;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  color: rgba(136, 136, 136, 0.9);
  margin-top: 23px;
}

.QAMainDiv .bottomBtnDiv {
  margin-top: 33px;
  padding-left: 24px;
}

.QAMainDiv .bottomBtnDiv p {
  opacity: 0.9;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 0px;
}

.QAMainDiv .bottomBtnDiv button {
  padding: 16px 21px;
  background-color: var(--yellow);
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  text-align: center;
  color: #fff;
  border: none;
  letter-spacing: 0.6px;
  border-radius: 6px;
  margin-top: 17px;
}

.QAMainDivMember .editMemberMainDiv {
  margin-top: 15px;
  padding-left: 34px;
  padding-right: 20px;
  padding-top: 37px;
  border-top: 1px solid #e1e1e1;
}

.QAMainDivMember .editMemberMainDiv .table tr th td {
  border: none !important;
  width: 50% !important;
}

.QAMainDivMember .editMemberMainDiv .table tr th {
  width: 20%;
}

.QAMainDivMember .editMemberMainDiv .table tr {
  margin-bottom: 20px;
  display: inline-table;
  width: 100%;
  border-style: hidden !important;
}

.QAMainDivMember .editMemberMainDiv .EditTr th {
  opacity: 0.9;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  color: rgba(0, 0, 0, 0.9);
}

.QAMainDivMember .editMemberMainDiv .EditTr th span {
  color: rgba(102, 102, 102, 0.9) !important;
}

.QAMainDivMember .editMemberMainDiv .EditTr td {
  opacity: 0.9;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  color: rgba(102, 102, 102, 0.9);
  padding-left: 20px;
}

.QAMainDivMember .editMemberMainDiv .EditTr td a {
  color: var(--yellow) !important;
  opacity: 0.9;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-decoration: none;
  padding: 5px 21px;
  border: solid 2px var(--yellow);
  border-radius: 6px;
}

.QAMainDivMember .editMemberMainDiv .EditTr td a.bg-orange {
  background-color: var(--yellow);
  border: solid 2px var(--yellow);
  color: var(--white) !important;
  text-align: center;
}

.QAMainDivMember .editMemberMainDiv .EditTr td a.bg-grey {
  background-color: var(--grey);
  border: solid 2px var(--grey);
  color: var(--white) !important;
  text-align: center;
}

.QAMainDivMember .editMemberMainDiv .tableBottomInputDiv {
  margin-top: -15px;
}

.QAMainDivMember .editMemberMainDiv .tableBottomInputDiv img {
  position: absolute;
  right: 20px;
  top: 10px;
}

.QAMainDivMember .editMemberMainDiv .tableBottomInputDiv input {
  width: 100%;
  height: 46px;
  border: solid 2px #e1e1e1;
  border-radius: 6px;
  padding-left: 10px;
}

.QAMainDivMember .editMemberMainDiv .tableBottomInputDiv input:focus-visible {
  outline: none !important;
}

.WithdrawMainDiv .withdrawDiv {
  padding-left: 0px;
  padding-top: 43px;
}

.WithdrawMainDiv .withdrawDiv p {
  opacity: 0.9;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.6px;
  color: rgba(102, 102, 102, 0.9);
}

.WithdrawMainDiv .withdrawDiv h5 {
  opacity: 0.9;
  font-size: 18px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 36px;
  margin-bottom: 20px;
}

.WithdrawMainDiv .withdrawDiv .YellowTxt {
  color: rgba(255, 114, 0, 0.9);
  opacity: 0.9;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.6px;
  margin-top: 27px;
}

.WithdrawMainDiv .withdrawDiv .formDiv {
  margin-top: 32px;
}

.WithdrawMainDiv .withdrawDiv .formDiv label {
  opacity: 0.9;
  font-size: 18px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.9);
}

.WithdrawMainDiv .withdrawDiv .formDiv input {
  border: solid 2px #e1e1e1;
  width: 348px;
  height: 46px;
  background-color: #fff;
  border-radius: 6px;
  margin-left: 30px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.6px;
  color: #c1c1c1;
  padding-left: 12px;
}

.WithdrawMainDiv .withdrawDiv .formDiv input:focus-visible {
  outline: none !important;
}

.WithdrawMainDiv .withdrawDiv .formDiv .secondInput {
  margin-left: 60px;
}

.WithdrawMainDiv .withdrawDiv .btnDiv {
  margin-top: 50px;
}

.withdrawSecondMain .WithdrawSecondSection p {
  opacity: 0.9;
  font-size: 19px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.8px;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 3px;
}

.withdrawSecondMain .WithdrawSecondSection .grayTxt {
  opacity: 0.9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.8px;
  text-align: center;
  color: rgba(102, 102, 102, 0.9);
}

.withdrawSecondMain .borderBottom {
  padding-bottom: 36px;
  border-bottom: 1px solid #e1e1e1;
}

.withdrawSecondMain .tableTabDiv {
  margin-top: 35px;
}

.withdrawSecondMain .tableTabDiv p {
  opacity: 0.9;
  font-size: 18px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.9px;
  color: rgba(0, 0, 0, 0.9);
}

.withdrawSecondMain .child-container {
  float: left;
  min-height: 71.5px;
  width: 33.25%;
}

.withdrawSecondMain .clearfix:before,
.withdrawSecondMain .clearfix:after {
  content: " ";
  display: table;
  clear: both;
}

/* COLORING STYLES */
.withdrawSecondMain .border-red {
  border: 1px solid #e1e1e1;
}

.withdrawSecondMain .border-green {
  border: 1px solid #e1e1e1;
}

.withdrawSecondMain .child-container-active {
  background-color: var(--yellow);
  float: left;
  min-height: 71.5px;
  width: 33.25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: #fff;
}

.withdrawSecondMain .child-container:nth-child(even) {
  background: #f6f7f9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: #888;
}

.withdrawSecondMain .child-container:nth-child(odd) {
  background: #f6f7f9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: #888;
}

.withdrawSecondMain .textArea textarea {
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  padding: 21px;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #c1c1c1;
}

.withdrawSecondMain .textArea textarea:focus-visible {
  outline: none !important;
}

.withdrawSecondMain .button-wrapper {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  padding-top: 27px;
}

.withdrawSecondMain .button-wrapper button {
  width: 300px;
  height: 60px;
  vertical-align: middle;
  border-radius: 6px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: -1px;
  text-align: center;
  color: var(--white);
  background-color: var(--bg-color);
  border: none;
  outline: none;
}

.viewPrice-tab .tab-section a {
  width: 50% !important;
}

.virePrice-personalNumberDiv .perNumbContentDIv {
  margin-top: 25px;
}

.virePrice-personalNumberDiv .perNumbContentDIv p {
  opacity: 0.9;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0.7px;
  color: rgba(102, 102, 102, 0.9);
}

.tableDivContainer {
  display: block;
  width: 100%;
  height: 275px;
  overflow-x: auto;
  overflow-y: hidden;
}

.tableDivMobile select.form-select {
  font-size: 14px;
}

.tableDivMobile {
  margin-top: 9px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.tableDivMobile table th {
  background-color: #f5f5f5 !important;
  border-left: 0px !important;
  /* border-right: 0px !important; */
  text-align: center;
  opacity: 0.9;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.8px;
  text-align: center;
  vertical-align: middle;
  color: rgba(102, 102, 102, 0.9);
}

.tableDivMobile table tbody tr td {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.8px;
  text-align: left;
  text-align: center;
  vertical-align: middle;
}

.tableDivMobile table tbody tr td.red {
  color: #f25f72;
}

.tableDivMobile table tbody tr td.blue {
  color: #5f9ee5;
}

.tableDivMobile table tbody tr td.purple {
  color: #c36ceb;
}

.tableDivMobile table tbody tr td.green {
  color: #59b00b;
}

.tableDivMobile table tbody tr td.orange {
  color: #fe7e00;
}

.virePrice-personalNumberDiv .perNumbContentDIv .tableDiv {
  margin-top: 9px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.virePrice-personalNumberDiv .perNumbContentDIv .tableDiv table th {
  background-color: #f5f5f5 !important;
  border-left: 0px !important;
  /* border-right: 0px !important; */
  text-align: center;
  opacity: 0.9;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.8px;
  text-align: center;
  vertical-align: middle;
  color: rgba(102, 102, 102, 0.9);
}

.virePrice-personalNumberDiv .perNumbContentDIv .tableDiv .mainTh {
  width: 12% !important;
  vertical-align: middle;
  height: 52px;
}

.virePrice-personalNumberDiv .perNumbContentDIv .tableDiv .mainTh select {
  opacity: 0.9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
}

.virePrice-personalNumberDiv .perNumbContentDIv .tableDiv table .redTr td {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.8px;
  text-align: left;
  color: #f25f72;
  text-align: center;
  vertical-align: middle;
}

.virePrice-personalNumberDiv .perNumbContentDIv .tableDiv table .blueTr td {
  color: #5f9ee5;
}

.virePrice-personalNumberDiv .perNumbContentDIv .tableDiv table .purpleTr td {
  color: #c36ceb;
}

.virePrice-personalNumberDiv .perNumbContentDIv .tableDiv table .greenTr td {
  color: #59b00b;
}

.virePrice-personalNumberDiv .perNumbContentDIv .tableDiv table .orangeTr td {
  color: #fe7e00;
}

.virePrice-personalNumberDiv .perNumbContentDIv .graphDiv {
  display: block;
  width: 100%;
  height: fit-content;
  min-height: 496px;
  margin-top: 50px;
  border: 0 solid red;
}

.FormHeading_div {
  border-bottom: 1px solid #e1e1e1;
}

.table.table-form {
  border-top: 2px solid #e2e2e2;
  border-bottom: 2px solid #e2e2e2;
  margin-bottom: 0;
}

.table.table-form tbody tr th {
  background-color: #f5f5f5 !important;
  color: #888888 !important;
  border-left: unset;
  border-right: 1px solid #e2e2e2 !important;
  text-align: center;
  font-size: 16.01px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  padding: 0 30px;
  min-height: 55px;
}

.table.table-form tbody tr {
  height: 55px;
}

.table.table-form tbody tr td {
  padding: 5px 30px;
  min-height: 55px;
  vertical-align: middle;
  border-right: unset;
}

h1.Form_Heading {
  color: #000;
  font-size: 35px;
  font-family: "NotoSansKR-Bold";
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.6px;
  letter-spacing: -1.75px;
  margin-bottom: 11px;
}

h3.FormDetails_Heading {
  color: #000;
  font-size: 24px;
  font-family: "NotoSansKR-Bold";
  line-height: 24px;
  letter-spacing: -1.2px;
  margin-bottom: 28px;
  margin-top: 30px;
}

div.FormDetails_Div {}

input.form-control:focus,
select.form-select:focus,
input.form-check-input:focus,
textarea.form-control {
  box-shadow: unset;
  /* font-size: 12px; */
}

input.form-control[type="text"] {
  border-width: 2px;
}

input.form-control.Table_Input {
  border-radius: 5px;
  height: 46px;
  border: 2px solid #e1e1e1;
  padding: 0 20px;
  background: #fff;
}

@media screen and (max-width: 992px) {
  input.form-control.input-zip {
    font-size: 12px;
  }
}

select.form-select.Table_Select {
  height: 46px;
  border: 2px solid #e1e1e1;
}

.btn.Table_Btn,
.btn.Table_Btn:hover,
.btn.Table_Btn:focus,
.btn.Table_Btn:active {
  width: 120px;
  height: 40px;
  background: #ff9c00;
  color: #fff;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 5px;
}

.w-20 {
  width: 20% !important;
}

.w-33 {
  width: 33% !important;
}

.w-89px {
  width: 89px !important;
}

.w-100px {
  width: 100px !important;
}

.w-75px {
  width: 75px !important;
}

.w-120px {
  width: 120px !important;
  min-width: 120px;
}

.w-133px {
  width: 133px !important;
}

.w-169px {
  width: 169px !important;
}

.w-135px {
  width: 100%;
  max-width: 135px !important;
}

.w-200px {
  width: 200px !important;
}

.w-300px {
  width: 300px !important;
}

.my-10px {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mx-10px {
  margin-left: 10px;
  margin-right: 10px;
}

.mt-10px {
  margin-top: 10px;
}

.mt-40px {
  margin-top: 40px;
}

.mt-30px {
  margin-top: 30px;
}

.mt-45px {
  margin-top: 45px;
}

.mb-20px {
  margin-bottom: 20px;
}

.mb-25px {
  margin-bottom: 25px;
}

.ms-md-10px {
  margin-left: 10px !important;
}

.ms-15px {
  margin-left: 15px;
}

.ms-20px {
  margin-left: 20px;
}

.ms-30px {
  margin-left: 30px;
}

.me-10px {
  margin-right: 10px;
}

.tr_header th {
  height: 18px;
  text-align: center;
  background-color: #f5f5f5 !important;
  color: #666;
  font-size: 16px;
  font-family: "NotoSansKR-Bold";
  vertical-align: middle;
  border-width: 0 !important;
}

.td_caption {
  color: rgba(102, 102, 102, 0.9);
  font-size: 16px;
  line-height: 24px;
  font-family: "NotoSansKR-Regular";
}

.td_caption span {
  color: rgba(255, 117, 25, 0.9);
  font-size: 16px;
  line-height: 24px;
  font-family: "NotoSansKR-Bold";
}

input.form-control[type="text"]::placeholder {
  color: #c1c1c1;
  font-size: 16px;
  /* line-height: 24px; */
  font-family: "NotoSansKR-Regular";
}

input[type="text"].Input_Search {
  background: url(../public/img/SearchIcon.svg) no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: center;
  background-size: auto;
}

button.Input_Button,
button.Input_Button:hover,
button.Input_Button:focus,
button.Input_Button:active {
  border: 2px solid #e1e1e1 !important;
  border-left: unset !important;
  background-color: #fff !important;
}

.txt_attach {
  color: #c1c1c1;
  font-size: 16px;
  line-height: 24px;
  font-family: "NotoSansKR-Regular";
}

textarea.form-textarea,
textarea.form-textarea:hover,
textarea.form-textarea:focus {
  width: 100%;
  height: 196px;
  resize: none;
  border: 2px solid #e1e1e1;
}

@media screen and (max-width: 1080px) {

  textarea.form-textarea,
  textarea.form-textarea:hover,
  textarea.form-textarea:focus {
    width: 100%;
    height: 196px;
    resize: none;
    border: 1px solid #e1e1e1;
  }
}

.f-15 {
  font-size: 15px;
}

.f-22 {
  font-size: 22px;
}

.f-light {
  color: #c1c1c1;
  font-size: 16px;
  line-height: 24px;
  font-family: "NotoSansKR-Regular";
}

.f-medium {
  color: #727172;
  font-size: 16px;
  line-height: 24px;
  font-family: "NotoSansKR-Regular";
}

.f-bold {
  font-family: "NotoSansKR-Bold";
}

.textarea_count {
  margin-top: -50px;
  margin-right: 20px;
  float: right;
  color: #c1c1c1;
  font-size: 16px;
  line-height: 41px;
  font-family: "NotoSansKR-Regular";
}

div.Para_Content {
  color: #f60;
  font-family: "NotoSansKR-Regular";
  font-size: 16px;
  line-height: 16px;
  margin-top: 30px;
}

div.Para_Content span {
  font-family: "NotoSansKR-Bold";
}

.btn.btn-main.btn-light,
.btn.btn-main.btn-light:hover,
.btn.btn-main.btn-light:focus,
.btn.btn-main.btn-light:active {
  width: 100%;
  max-width: 390px;
  height: 56px;
  color: #fff;
  font-family: "NotoSansKR-Bold";
  font-size: 20px;
  background-color: #c7c7c7;
  border: 1px solid #c7c7c7;
  display: block;
  margin: 50px auto 35px auto;
  text-wrap: nowrap;
  white-space: nowrap;
}

.pc_view {
  display: block !important;
}

.mobile_view,
.mobile_sm_view {
  display: none !important;
}

div.frm_request_container {
  padding: 0;
}

/* QA css end */

.td_chk3_outer {
  display: flex;
  align-items: center;
}

.frm_request_wrapper {
  margin-top: 45px;
}

.frm_request_container2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.container-main {}

.modal-552px .modal-content {
  width: 100%;
  min-width: 552px;
  max-width: 552px;
}

.my_modal .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 67px;
}

.my_modal .modal-title {
  color: #000;
  font-family: "NotoSansKR-Bold";
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.my_modal .modal-header .btn-close {
  background: url(../public/img/icon-close.png) no-repeat center;
}

.my_modal .modal-body {
  padding: 30px 30px;
}

.table-part {
  /* min-height: 100vh; */
}

.min-100px {
  min-width: 100px;
}

.min-70px {
  min-width: 70px;
}

.ms-chk {
  margin-left: 35px;
}

.padding-qna {
  padding: 85px 0px 100px 0px;
}

.daum_search_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.daum_search_wrapper .fileBtn {
  margin-right: 10px;
}

.interest_img {
  width: 35px;
  height: 29px;
}

div.yellow_hand {
  align-items: center;
  width: fit-content !important;
}

img.yellow-hand {
  width: 32px;
  /* height: 30px; */
}

div.div_yellow_hand {
  align-items: center;
}

div.cardMainWrapper .dropdown button,
div.pageBreadcrumb .dropdown.btn-group button {
  border: unset !important;
}

input.input-zip {
  width: 100%;
  min-width: 70px;
  margin-right: 10px;
}

label.btn.btn-primary.btn-zip {
  height: 46px;
}

.buttons-end {
  display: flex;
  justify-content: flex-end;
}

.mb-120px {
  margin-bottom: 120px;
}

button.btn_buy {
  float: right;
  max-width: unset !important;
}

.mw-1110px {
  max-width: 1110px !important;
}

.e_div {
  /* background-color: yellow; */
}

@media screen and (max-width: 1080px) {
  div.div_yellow_hand {
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  div.div_yellow_hand>div:first-child {
    width: unset;
  }
}

/* mobile media query */
@media screen and (max-width: 992px) {
  .request-information .table-part .table td {
    /* padding-left: 10px; */
  }

  .headerTopOffcanvas.offcanvas .offcanvas-body {
    overflow-y: auto;
  }

  img.yellow-hand {
    margin-right: 10px;
  }

  div.type_2 {
    width: 100%;
  }

  button.btn.btn-primary.btn_view_listing {
    max-width: unset;
    width: 100% !important;
    height: 41px !important;
    font-size: 20px !important;
  }

  .frm_request_container2 {
    margin-bottom: 0;
  }

  .frm_request_wrapper {
    margin-top: 25px;
  }

  .td_chk3_outer {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }

  .td_chk3_outer .CustomCheckbox {
    width: 100% !important;
  }

  select.form-select.Table_Select {
    height: 31px;
    border-width: 1px;
    font-size: 12px;
  }

  .CustomCheckerBox input[type="checkbox"] {
    width: 21px;
    height: 21px;
  }

  .CustomCheckerBox .form-check-label,
  .CustomCheckerBox .form-check-label-ml-7 {
    font-size: 12px;
    line-height: 30px;
    text-wrap: nowrap;
    white-space: nowrap;
  }

  textarea.form-textarea,
  textarea.form-textarea:hover,
  textarea.form-textarea:focus {
    height: 103px;
  }

  .textarea_count {
    font-size: 12px;
    line-height: 21px;
    margin-top: -25px;
    margin-right: 9px;
  }

  div.Para_Content {
    font-size: 12px;
    line-height: 15px;
  }

  div.td_caption,
  .td_caption span {
    font-size: 12px;
    line-height: 15px;
  }

  /*.btn.btn-primary,*/
  /*.btn.btn-primary:hover,*/
  /*.btn.btn-primary:focus,*/
  /*.btn.btn-primary:active,*/
  /*.btn.btn-primary:visited {*/
  /*  font-size: 12px;*/
  /*  width: 59px;*/
  /*  height: 31px;*/
  /*}*/

  .btn.btn-primary {
    font-size: 12px;
    width: 59px;
    height: 31px;
  }

  .fileBtn {
    font-size: 12px;
    width: 59px !important;
    min-width: unset !important;
    height: 31px;
    padding: 0 10px;
  }

  .txt_attach {
    font-size: 11px;
  }

  input.form-control[type="text"]::placeholder {
    font-size: 12px;
  }

  input.form-control.Table_Input {
    padding: 0 14px;
    height: 31px;
    border-width: 1px;
    font-size: 12px;
  }

  .table.table-form tbody tr,
  .table.table-form thead tr {
    height: 32px;
  }

  .table.table-form thead tr.tr_header {
    height: 18px;
    max-height: 18px;
  }

  .table.table-form thead tr.tr_header th {
    font-size: 12px;
    padding: 0;
  }

  body * {
    font-size: 12px;
  }

  .CustomCheckbox input[type="radio"] {
    width: 16px;
    height: 16px;
  }

  .CustomCheckbox input[type="radio"].form-check-input:checked:after {
    width: 8px;
    height: 9px;
  }

  div.frm_request_container {
    padding: 0 20px;
    margin: 56px 0 0 0;
    width: 100% !important;
  }

  @media screen and (max-width: 992px) {
    div.frm_request_container {
      padding: 0 20px;
      margin: 25px 0 0 0;
      width: 100% !important;
    }
  }

  h3.FormDetails_Heading {
    font-size: 17px;
    line-height: 17px;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .w-300px,
  .w-169px,
  .w-89px,
  .w-120px {
    width: 100% !important;
  }

  .w-sm-59px {
    width: 59px !important;
  }

  .h-sm-31px {
    height: 31px !important;
  }

  .pc_view {
    display: none !important;
  }

  .mobile_view {
    display: block !important;
  }

  .table.table-form tbody tr td,
  .table.table-form thead tr th {
    padding: 2px 8px;
    vertical-align: middle;
  }

  .table.table-form tbody tr th {
    padding: 0 8px;
    min-width: 90px;
    width: 25.92% !important;
    font-family: "NotoSansKR-Medium";
    font-size: 12px;
  }

  /*   ===================Start Pages>MyPage>Main=================== */

  .card_box .card_part .card_box {
    border-radius: 10px;
  }

  .mypage_wrapper .navtab_header .navtab_inner {
    column-gap: 100px;
  }

  .mypage_wrapper .banner_part .banner_inner_part {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 35px 40px 0px 40px;
  }

  .mypage_wrapper .card_part .card_box .top_part {
    padding: 0 40px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part {
    width: 100%;
    display: flex;
    padding-top: 30px;
    flex-direction: column;
  }

  .mypage_wrapper .card_part .card_box .bottom_part .left_part {
    width: 100%;
    display: flex;
    align-items: center;
    border-right: none;
    border-bottom: 1px solid rgba(45, 70, 98, 0.15);
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part .right_part {
    width: 100%;
    padding-top: 20px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_right_part {
    width: calc(100% - 212px);
    display: flex;
    justify-content: flex-end;
  }

  .mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_left_part {
    padding-left: 0px;
  }

  .card_part .bottom_part .left_part .inner_left_part {
    margin-left: 0px;
    width: 276px;
  }

  /* 2409003 start */
  .mypage_wrapper .card_part .card_box .content_part {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 35px 40px 0px 40px;
  }

  .mypage_wrapper .card_part .card_box .top_part {
    padding: 0 40px;
  }

  .mypage_wrapper .card_part .card_box .content_part {
    width: 100%;
    display: flex;
    padding-top: 30px;
    flex-direction: column;
  }

  .mypage_wrapper .card_part .card_box .content_part .left_part {
    width: 100%;
    display: flex;
    align-items: center;
    border-right: none;
    border-bottom: 1px solid rgba(45, 70, 98, 0.15);
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .mypage_wrapper .card_part .card_box .content_part .right_part {
    width: 100%;
    padding-top: 20px;
  }

  .mypage_wrapper .card_part .card_box .content_part .right_part .inner_right_part {
    width: calc(100% - 212px);
    display: flex;
    justify-content: flex-end;
  }

  .mypage_wrapper .card_part .card_box .content_part .right_part .inner_left_part {
    padding-left: 0px;
  }

  .card_part .content_part .left_part .inner_left_part {
    margin-left: 0px;
    width: 276px;
  }
  /* 2409003 end */


  .parentTab_wrapper .parent_tab .main_tab {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 64px;
  }

  .my-listing .request-information .approvel-top-section {
    column-gap: 50px;
  }

  .request-information .approvel-top-section .icons-part .icons::before {
    width: 41px;
    left: 75px;
  }

  .my-listing .request-information .approvel-top-section .icons-part .icons::after {
    width: 30px;
    left: -52px;
  }

  .my-listing .request-information .approvel-top-section .icons-part:first-child .icons::after {
    background-color: transparent;
  }
}

@media (max-width: 767.98px) {
  .QAMainDivMember .editMemberMainDiv .table tr {
    margin-bottom: 0;
  }

  .QAMainDivMember .editMemberMainDiv .EditTr td a.btn_outline {
    padding: 0;
    width: 47px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    font-size: 10px;
    letter-spacing: -0.5px;
    border-width: 1px;
  }

  .chkButton .form-check-input {
    width: 26px;
    height: 26px;
  }

  .CustomCheckbox.chkButton .form-check-input:checked {
    background: url(../public/img/btn-checkbox-checked_mobile.png) no-repeat center;
    background-size: contain;
  }

  .CustomCheckbox.chkButton+div {
    letter-spacing: -0.8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 12px;
  }

  .w-sm-175px {
    min-width: 175px;
    max-width: 175px;
    width: 100% !important;
  }

  .w-sm-120px {
    min-width: 120px;
    max-width: 120px;
    width: 100% !important;
  }

  .w-sm-100px {
    min-width: 100px;
    max-width: 100px;
    width: 100% !important;
  }

  .w-sm-75px {
    min-width: 75px;
    max-width: 75px;
    width: 100% !important;
  }

  .w-sm-65px {
    min-width: 65px;
    max-width: 65px;
    width: 100% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .chk_col4_container {
    flex-wrap: wrap;
  }

  .chk_col4 {
    /* min-width: 150px; */
    width: 50%;
  }

  .virePrice-personalNumberDiv .perNumbContentDIv .graphDiv {
    margin-bottom: 100px;
  }

  .container-content {
    margin: 0 20px;
  }

  .buttons-end {
    margin-top: 10px;
  }

  input.input-zip {
    border-right: unset !important;
    margin-right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  label.btn.btn-primary.btn-zip {
    color: transparent;
    background-color: unset;
    border: 2px solid #e1e1e1;
    border-left: unset;
    background: url(../public/img/icon_find_gray.png) no-repeat;
    background-size: 17px 18px;
    background-position: calc(100% - 10px) center;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  @media (max-width: 767px) {
    .h-sm-36px {
      height: 36px !important;
    }

    .border-sm-1 {
      border-width: 1px !important;
    }

    label.btn.btn-primary.btn-zip {
      color: transparent;
      background-color: unset;
      border: 1px solid #e1e1e1;
      border-left: unset;
      background: url(../public/img/icon_find_gray.png) no-repeat;
      background-size: 17px 18px;
      background-position: calc(100% - 10px) center;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 32px;
    }
  }

  .container {
    width: calc(100% - 16px) !important;
  }

  .daum_search_wrapper {
    flex-direction: column;
  }

  .daum_search_wrapper .fileBtn {
    margin-right: 0;
  }
}

@media (max-width: 991.98px) {
  .customHeader .navbar-brand {
    margin-right: 30px;
  }

  .header_menu_item+.header_menu_item {
    margin-left: 24px;
  }

  .header_menu_child {
    padding-left: 24px;
    margin-top: 0;
  }

  .header_menu_child_item+.header_menu_child_item {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {

  table tr td.va-sm-top,
  table tr th.va-sm-top {
    vertical-align: top !important;
    padding-top: 18px;
  }

  .customer_tab .customer-service {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .question_answer {
    padding: 25px 0px 100px 0px;
  }

  .question_answer .sub_title p {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .question_answer .subject input {
    padding: 6px 16px 6px 16px;
    border: 1px solid black;
  }

  .question_answer .subject input::placeholder {
    font-size: 13px;
  }

  .question_answer .detail textarea {
    border: 1px solid black;
  }

  .question_answer .detail textarea::placeholder {
    font-size: 13px;
  }

  .question_answer .detail textarea::placeholder {
    font-size: 13px;
  }

  .question_answer .subject .title {
    top: 5px;
    font-size: 13px;
  }

  .question_answer .attached_file {
    font-size: 12px;
    border: 1px solid black;
  }

  .question_answer .attached_file .title {
    font-size: 12px;
    color: #c1c1c1;
  }

  .attached_file {
    padding: 8px 16px 8px 16px;
  }

  .attached_file .attachment {
    top: 5px;
  }

  .attached_file .plus {
    top: 15px;
    width: 12px;
    height: 12px;
  }

  .question_answer .main-btn button {
    width: 100%;
    height: 50px;
    font-size: 16px;
  }

  .question_answer .main-btn {
    margin-top: 25px;
  }

  .question_answer .detail textarea {
    padding: 8px 16px 8px 16px;
    height: 260px;
  }

  .question_answer .subject {
    margin-bottom: 20px;
  }

  .question_answer .detail {
    position: relative;
    margin-bottom: 20px;
  }

  .question_answer .detail .title {
    bottom: 10px;
    font-size: 12px;
  }

  .question_answer .qa-label {
    padding-top: 26px;
    font-size: 11px;
  }

  .customer_tab {
    padding: 94px 0px 60px 0px;
    /* height: 145px; */
  }

  @media screen and (max-width: 768px) {
    .customer_tab {
      padding: 25px 0px 86px 0px;
      letter-spacing: -0.5px;
      font-size: 18px;
    }
  }

  .mypage_wrapper .navtab_header {
    height: 45px;
  }

  .mypage_wrapper .card_part.mt_top {
    margin-top: 25px;
    margin-bottom: 28px;
  }

  .customer_tab .tab-section {
    margin-top: 0px;
    display: flex;
    width: 100%;
    border: 1px solid var(--gray);
  }

  .mypage_wrapper .card_part .card_box {
    width: 100%;
    background: var(--white);
    padding: 20px 0px 28px 0px;
    box-shadow: 4px 6.9px 27px 0 rgba(45, 70, 98, 0.15);
    border-radius: 18px;
    margin-top: 15px;
  }

  .card_part .bottom_part .left_part .inner_right_part {
    margin-left: 0px;
    width: calc(100% - 212px);
    max-width: 276px;
  }

  .mypage_wrapper .banner_part .banner_inner_part .banner_icon_box .circle_box {
    width: 62.5px;
    height: 62.5px;
    font-size: 24px;
  }

  .mypage_wrapper .banner_part .banner_inner_part .banner_icon_box .circle_tittle {
    font-size: 11px;
  }

  .mypage_wrapper .banner_part .banner_inner_part {
    column-gap: 23px;
  }

  .mypage_wrapper .navtab_header a {
    font-size: 14px;
  }

  .mypage_wrapper .navtab_header .navtab_inner {
    column-gap: 32px;
  }

  .mypage_wrapper .card_part h2 {
    font-size: 16px;
  }

  .mypage_wrapper .card_part .card_box .top_part .breadcrumb ul {
    font-size: 14px;
  }

  .mypage_wrapper .card_part .card_box .top_part .breadcrumb ul li {
    font-size: 14px;
  }

  /*.mypage_wrapper .card_part .card_box .top_part.breadcrumb d-flex justify-content-between ul li {*/
  /*  font-size: 20px;*/
  /*}*/

  .card_part .bottom_part .left_part .inner_left_part .number {
    font-size: 24px;
  }

  .card_part .bottom_part .left_part .inner_left_part .value {
    font-size: 14px;
  }

  .card_part .bottom_part .left_part .inner_left_part {
    column-gap: 9px;
  }

  .mypage_wrapper .right_part_value {
    font-size: 14px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_left_part {
    font-size: 14px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_right_part button {
    font-size: 14px;
  }

  /*  240903 start */
  /* .mypage_wrapper .card_part .card_box .content_part {
  } */
  .mypage_wrapper .card_part .card_box .content_part .left_part .inner_part .first_value {
    font-size: 15px;
  }
  .mypage_wrapper .card_part .card_box .content_part .left_part .inner_part .second_value2 {
    font-size: 14px;
  }
  .mypage_wrapper .card_part .card_box .content_part .right_part .inner_right_part button{
    font-size: 14px;
  }
  /* 240903 end */

  .mypage_wrapper .banner_part {
    background: #fbebc8;
    width: 100%;
    height: 120px;
    margin: 0px 0px 25px;
  }

  .approvel-bottom-section button {
    margin-top: 9px;
    font-size: 10px;
  }

  .parentTab_wrapper .sub_main_tab .navsub_tab {
    width: 100px;
    height: 31px;
    font-size: 12px;
  }

  .parentTab_wrapper .parent_tab .main_tab {
    /* column-gap: 32px; */
    column-gap: 11px;
  }

  .parentTab_wrapper .parent_tab {
    height: 45px;
    margin-top: 56px;
  }

  .parentTab_wrapper .parent_tab .main_tab .nav_link {
    font-size: 14px;
    text-wrap: nowrap;
    white-space: nowrap;
  }

  .main-heading h1 {
    font-size: 16px;
  }

  .request-transiction {
    padding: 25px 0px 30px 0px;
  }

  .request-information {
    margin: 10px 0px;
    padding: 35px 0px;
  }

  .request-information .breadcrumb {
    padding-bottom: 15px;
  }

  .request-information .breadcrumb ul li {
    font-size: 14px;
  }

  .request-information .breadcrumb ul {
    column-gap: 17px;
  }

  .request-information .breadcrumb .date-part p {
    font-size: 10px;
  }

  .request-information .approvel-bottom-section span {
    font-size: 10px;
  }

  .request-information .breadcrumb ul li::after {
    right: -12px;
  }

  .request-information .approvel-top-section {
    column-gap: 45px;
    padding: 14px 0px 12px 0px;
  }

  .personalSellingPage .cardMainWrapper {
    margin-bottom: 80px;
  }

  .request-information .approvel-top-section .icons-part .icons {
    box-sizing: border-box;
    width: 33px;
    height: 33px;
  }

  .request-information .approvel-top-section .icons-part .icons::after {
    left: -33px;
  }

  .request-information .approvel-top-section .icons-part .icons::before {
    left: 33px;
  }

  .request-information .approvel-top-section .icons-part .icon-title {
    font-size: 9px;
  }

  .request-information .table-part .sub-heading h3 {
    font-size: 13px;
    padding: 20px 0px 12px 0px;
  }

  .request-information .table-part .table td {
    font-size: 11px;
    line-height: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
  }

  .request-information .table-part .table td span {
    font-size: 11px;
  }

  .request-information .button-wrapper {
    column-gap: 10px;
  }

  .request-information .button-wrapper button {
    width: 157.5px;
    height: 40px;
    font-size: 15px;
  }

  .request-information .approvel-bottom-section {
    background-color: #fbebc8;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    padding: 15px 20px 15px 20px;
  }

  .my-listing .request-information .approvel-top-section {
    column-gap: 16px;
  }

  .my-listing .request-information .approvel-top-section .icons-part .icons {
    box-sizing: border-box;
    width: 33px;
    height: 33px;
  }

  .my-listing .request-information .approvel-top-section .icons-part .icons::after {
    left: -33px;
    width: 0;
  }

  .my-listing .request-information .approvel-top-section .icons-part .icons::before {
    left: 33px;
    width: 100%;
  }

  .customer_tab .accordion-button {
    padding: 20px !important;
  }

  .customer_tab .tab-section a {
    font-size: 14px;
    padding: 12px 0px 12px 0px;
  }

  .customer_tab .customer-service .accordion-button .btn-text {
    font-size: 14px;
  }

  .customer_tab .customer-service .accordion-button .btn-date {
    padding-top: 5px;
    font-size: 14px;
  }

  .customer_tab .customer-service .accordion-collapse p {
    font-size: 13px;
  }

  .customer_tab .customer-service .accordion-button {
    padding: 10px 20px !important;
  }

  .customer_tab .customer-service .accordion-collapse .under-accordian {
    padding: 20px;
    font-size: 14px;
  }

  .customer_tab .accordion-button::after {
    transform: scale(0.7) !important;
  }

  .notification-setting .checkbox-div label {
    margin-left: 8px !important;
  }

  .personalSellingPage .cardMainWrapper {
    margin-bottom: 80px;
  }

  .font16 {
    font-size: 11px !important;
  }

  .main-heading h1 {
    font-size: 18px;
    letter-spacing: -0.5px;
  }

  .notification-information-main .cardDIv {
    margin-top: 15px !important;
  }

  .notification-setting .checkbox-div .checkBoxWidth {
    /* width: 25% !important; */
    text-wrap: nowrap;
    white-space: nowrap;
  }

  .notification-setting .checkbox-div label {
    font-size: 11px !important;
    text-wrap: nowrap;
    white-space: nowrap;
  }

  .notification-setting .form-check {
    padding-left: 15px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .notification-setting .checkbox-div input {
    height: 18px !important;
    width: 18px !important;
  }

  .canvasLeftSection {
    width: 100% !important;
    padding-bottom: 30px;
  }

  .canvasRightSection {
    width: 100%;
    padding-left: 0px;
  }

  .CanvasWrapper {
    flex-flow: column-reverse;
    margin-top: 0px !important;
  }

  .canvasRightSection .canvasRightContentBottomDiv h5 {
    text-align: start;
  }

  .canvasRightSection .canvasRightContentBottomDiv p {
    font-size: 12px;
    text-align: start;
  }

  .canvasRightSection .canvasRightContentBtnDiv .yellowBtn {
    width: 155px;
  }

  .canvasRightSection .canvasRightContentBtnDiv .yellowBtnBdr {
    width: 155px;
  }

  .canvasLeftBlockMenuMainLists ul li {
    font-size: 11px;
    padding-left: 0px !important;
    line-height: 9px;
  }

  .canvasLeftBlockMenuMain span {
    top: 5px !important;
  }

  .canvasLeftBlockMenuMain {
    font-size: 15px;
  }

  .canvasRightSection .canvasRightContentBottomDiv {
    margin-top: 20px;
  }

  .offcanvas.offcanvas-end {
    border-left: 0px !important;
  }

  .canvasRightContentBottomDiv {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .CanvasWrapper h6 {
    font-size: 16px;
  }

  .canvasRightSection .canvasRightContentBtnDiv .afterLoginTxt span {
    line-break: strict;
    display: block;
  }

  .canvasRightSection .canvasRightBottomAfterLoginDiv .largeBtnDiv {
    width: 155px;
  }

  .canvasRightSection .canvasRightBottomAfterLoginDiv .largeBtnDiv span {
    font-size: 15px;
  }

  .canvasRightSection .canvasRightBottomAfterLoginDiv .largeBtnDiv .numberDIv {
    font-size: 20px;
  }

  .desktopHeader .offcanvas-header .btn-close {
    font-size: 16px;
  }

  .mobileHeader span {
    margin-top: 5px !important;
  }

  .content-container {
    padding: 25px 0px 100px 0px;
  }

  .QAMainDiv .QADiv .borderDIv {
    font-size: 11px;
    padding-top: 25px;
    padding-bottom: 18px;
    padding-left: 22px;
  }

  .QAMainSection {
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  .QAMainDiv .QADiv .QADivSec {
    padding-left: 22px;
    padding-top: 20px;
  }

  .QAMainDiv .QADiv .QADivSec p {
    font-size: 12px;
  }

  .QAMainDiv .QADiv .QADivSec span {
    font-size: 11px;
  }

  .QAMainDiv .QADiv .QADivSec .dateTxt {
    font-size: 9px;
  }

  .QAMainDiv .QADivBgGray {
    padding-left: 22px;
    padding-top: 22px;
  }

  .QAMainDiv .QADivBgGray .iconLine {
    width: 9px;
    top: 30px;
  }

  .QAMainDiv .QADivBgGray .iconLineDiv p {
    font-size: 12px;
  }

  .QAMainDiv .QADivBgGray .iconLineDiv {
    padding-left: 18px;
  }

  .QAMainDiv .QADivBgGray .iconLineDiv span {
    font-size: 11px;
    line-height: 1.27;
    margin-top: 15px;
  }

  .QAMainDiv .QADivBgGray .iconLineDiv .dateTxt {
    font-size: 9px;
    margin-top: 15px;
  }

  .QAMainDiv .bottomBtnDiv p {
    font-size: 12px;
  }

  .QAMainDiv .bottomBtnDiv {
    margin-top: 25px;
    padding-left: 22px;
  }

  .QAMainDiv .bottomBtnDiv button {
    padding: 19px 21px 21px 20px;
    font-size: 10px;
    margin-top: 15px;
  }

  .QAMainDiv {
    padding-bottom: 36px;
  }

  .QAMainDivMember .editMemberMainDiv {
    padding-left: 0px;
    padding-right: 0px;
  }

  .QAMainDivMember .editMemberMainDiv .EditTr th {
    font-size: 12px;
    letter-spacing: -0.6px;
    font-family: "NotoSansKR-Medium";
    font-weight: normal;
  }

  .QAMainDivMember .editMemberMainDiv .EditTr span {
    font-size: 12px;
  }

  .QAMainDivMember .editMemberMainDiv .EditTr td {
    padding-left: 10px;
    font-size: 12px;
  }

  .QAMainDivMember .editMemberMainDiv .EditTr td a {
    font-size: 12px;
    text-wrap: nowrap;
    white-space: nowrap;
    line-height: 21px;
  }

  .QAMainDivMember .editMemberMainDiv .table tr th {
    padding-left: 0;
    padding-right: 0;
    width: 24%;
    text-wrap: nowrap;
    white-space: nowrap;
    vertical-align: middle;
  }

  .WithdrawMainDiv .withdrawDiv p {
    font-size: 11px;
  }

  .WithdrawMainDiv .withdrawDiv {
    padding-top: 30px;
  }

  .WithdrawMainDiv .withdrawDiv h5 {
    font-size: 12px;
    margin-top: 25px;
  }

  .WithdrawMainDiv .withdrawDiv .YellowTxt {
    font-size: 11px;
    letter-spacing: 0.4px;
  }

  .WithdrawMainDiv .withdrawDiv .formDiv {
    display: block !important;
    margin-top: 0px;
  }

  .WithdrawMainDiv .withdrawDiv .formDiv input {
    margin-left: 0px;
    margin-top: 10px;
    width: 100%;
    height: 40px;
  }

  .WithdrawMainDiv .withdrawDiv .formDiv label {
    font-size: 12px;
    margin-top: 22px;
  }

  .WithdrawMainDiv .withdrawDiv .formDiv .secondInput {
    margin-left: 0px;
  }

  .WithdrawMainDiv .withdrawDiv .btnDiv {
    margin-top: 25px;
  }

  .QAMainDivMember .editMemberMainDiv {
    padding-top: 20px;
  }

  .withdrawSecondMain .WithdrawSecondSection p {
    font-size: 15px;
    margin: 0px;
  }

  .withdrawSecondMain .WithdrawSecondSection span {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .withdrawSecondMain .borderBottom {
    padding-bottom: 20px;
  }

  .withdrawSecondMain .WithdrawSecondSection .grayTxt {
    font-size: 13px;
  }

  .withdrawSecondMain .tableTabDiv p {
    font-size: 14px;
    letter-spacing: -0.2px;
  }

  .withdrawSecondMain .tableTabDiv {
    margin-top: 20px;
  }

  .withdrawSecondMain .child-container {
    float: left;
    min-height: 50px;
    width: 50%;
  }

  .withdrawSecondMain .child-container-active {
    float: left;
    min-height: 50px;
    width: 50%;
    font-size: 11px;
  }

  .withdrawSecondMain .child-container:nth-child(even) {
    font-size: 11px;
  }

  .withdrawSecondMain .child-container:nth-child(odd) {
    font-size: 11px;
  }

  .withdrawSecondMain .textArea textarea {
    font-size: 10px;
    padding: 16px;
  }

  .withdrawSecondMain .button-wrapper button {
    font-size: 15px;
  }

  .withdrawSecondMain .button-wrapper {
    padding-top: 25px;
  }

  .withdrawSecondMain .button-wrapper button {
    height: 40px;
  }

  /* .Onmobile { padding-left: 0px !important; padding-right: 0px !important; margin-left: 0px !important; margin-right: 0px !important; } */
}

@media screen and (max-width: 592px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pb-xs-0 {
    padding-bottom: 0 !important;
  }

  .btn.btn-auth {
    max-width: 83px;
    font-size: 12px;
    line-height: 12px;
    height: 36px !important;
    letter-spacing: -0.6px;
  }

  div.card-dropdown>div:last-child button.btn_buy,
  div.card-dropdown>div:last-child button.btn.btn-primary.btn_buy,
  div.card-dropdown>div>div:last-child button.btn.btn-primary.btn_buy {
    /* max-width: 154px !important;
    width: 154px;
    min-width: 154px !important; */
    height: 41px !important;
    font-size: 14px !important;
    letter-spacing: -0.8px;
    font-family: "NotoSansKR-SemiBold";
  }

  div.card-dropdown>div:first-child {
    padding: 20px 20px 25px 20px;
  }

  .QAMainDivMember .editMemberMainDiv .EditTr td .buttons-end {
    width: 100%;
  }

  .QAMainDivMember .editMemberMainDiv .EditTr td a.bg-orange {
    width: 50%;
    padding-left: 0;
    padding-right: 0;
  }

  .QAMainDivMember .editMemberMainDiv .EditTr td a.bg-grey {
    width: 50%;
    padding-left: 0;
    padding-right: 0;
  }

  .notification-setting .checkbox-div {
    padding-left: 0;
  }

  table.table tr td {
    font-family: "NotoSansKR-Medium";
    font-size: 12px;
    letter-spacing: -0.6px;
  }

  .personalSellingPage .cardMainWrapper {
    margin-top: 15px;
  }

  .e_div>div {
    flex-direction: column;
  }

  .e_div>div>div {
    margin-bottom: 10px;
  }

  .e_div>div:last-child>div:last-child {
    margin-bottom: 0;
  }

  .container-content {
    margin: 0 10px;
  }

  .mypage_wrapper .right_part_value .first_value {
    font-size: 14px;
    line-height: 12px;
  }

  .mypage_wrapper .right_part_value .second_value {
    font-size: 14px;
    line-height: 12px;
  }

  .mypage_wrapper .right_part_value .second_value2 {
    font-size: 14px;
    line-height: 12px;
  }

  .card_part .bottom_part .left_part .inner_right_part .part_top .right_part_value .second_value {
    font-size: 14px;
    line-height: 12px;
  }

  .card_part .bottom_part .left_part .inner_right_part .part_top .right_part_value {
    font-size: 14px;
    line-height: 12px;
  }

  .card_part .bottom_part .left_part .inner_right_part .part_top_bottom {
    font-size: 14px;
    line-height: 12px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_left_part .second_value {
    font-size: 13px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_left_part .first_value {
    font-size: 14px;
  }
  .mypage_wrapper .card_part .card_box .bottom_part .left_part .inner_left_part .second_value {
    font-size: 13px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part .left_part .inner_left_part .first_value {
    font-size: 14px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_left_part .value {
    font-size: 14px;
  }

  .card_part .bottom_part .left_part .inner_left_part {
    align-items: baseline;
  }

  /*  240903 start */
  .mypage_wrapper .card_part .card_box .content_part .left_part .inner_part .first_value {
    font-size: 15px;
  }
  .mypage_wrapper .card_part .card_box .content_part .left_part .inner_part .second_value2 {
    font-size: 14px;
  }
  .mypage_wrapper .card_part .card_box .content_part .right_part .inner_right_part button{
    font-size: 14px;
  }
  /* 240903 end */
  

  .ms-sm-0px {
    margin-left: 0 !important;
  }

  .h-sm-31px {
    height: 31px !important;
  }

  .ms-chk {
    margin-left: 0;
  }

  .CustomCheckbox input[type="radio"]~label {
    margin-left: 5px;
    /* margin-top: 3px; */
    font-size: 12px;
    font-family: "NotoSansKR-Medium";
  }

  .form-check-inline {
    display: inline-block;
    /* margin-right: 0.5rem !important; */
  }

  .table.table-form tbody tr th {
    font-size: 12px;
  }
}

@media (max-width: 575px) {
  img.yellow-hand {
    margin-right: 0;
  }

  .iconBack {
    width: 9px;
    height: 12px;
  }

  .mobile_sm_view {
    display: inline-block !important;
  }

  .f-sm-15 {
    font-size: 15px;
  }

  .f-sm-12 {
    font-size: 12px;
  }

  .f-sm-16 {
    font-size: 16px !important;
  }

  .f-sm-14 {
    font-size: 14px !important;
  }

  .h-sm-50px {
    height: 50px !important;
  }

  .my_modal .modal-body {
    padding: 25px 20px;
  }

  .my_modal .modal-header .btn-close {
    background-size: 17px;
  }

  .my_modal .modal-header {
    height: 53px;
  }

  .my_modal .modal-title {
    font-size: 22px;
    line-height: 22px;
  }

  .table.table-form.table-modal tbody tr th {
    width: 43.75% !important;
  }

  .modal-552px .modal-content {
    min-width: unset;
  }

  /* 240903 start */
  .mypage_wrapper .card_part .card_box .content_part .right_part .inner_right_part {
    /* width: calc(100% - 125px); */
    width: 125px;
    max-width: 167.5px;
    margin-left: auto;
  }
  .card_part .content_part .left_part .inner_part {
    margin-left: 10px;
    /* width: calc(100% - 125px); */
    width: 100%;
    max-width: 250px;
  }

  .mypage_wrapper .card_part .card_box .content_part {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 21px 0px 15px;
  }

  .card_part .content_part .left_part .inner_part .part_top {
    gap: 10px;
    flex-direction: column;
  }

  .card_part .content_part .left_part .inner_part .part_bottom {
    padding-top: 10px;
    gap: 10px;
    flex-direction: column;
  }
  .card_part .content_part .left_part .inner_part .part_top .right_part_value {
    gap: 10px;
  }

  .card_part .content_part .left_part .inner_part .part_bottom .right_part_value {
    gap: 10px;
  }
  .card_part .card_box .content_part .right_part .inner_right_part .button {
    font-size: 14px;
  }
  /* 240903 end */

  .mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_left_part {
    width: 125px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_right_part {
    width: calc(100% - 125px);
    max-width: 167.5px;
    margin-left: auto;
  }

  .card_part .bottom_part .left_part .inner_right_part {
    margin-left: 0px;
    width: calc(100% - 125px);
    max-width: 167.5px;
  }

  .card_part .bottom_part .left_part .inner_left_part {
    max-width: 125px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 21px 0px 21px;
  }

  .mypage_wrapper .card_part .card_box .top_part {
    padding: 0 21px;
  }

  .card_part .bottom_part .left_part .inner_right_part .part_top {
    gap: 10px;
    flex-direction: column;
  }

  .card_part .bottom_part .left_part .inner_right_part .part_bottom {
    padding-top: 10px;
    gap: 10px;
    flex-direction: column;
  }

  .card_part .bottom_part .left_part .inner_right_part .part_top .right_part_value {
    gap: 10px;
  }

  .card_part .bottom_part .left_part .inner_right_part .part_bottom .right_part_value {
    gap: 10px;
  }

  .mypage_wrapper .card_part .card_box .bottom_part .right_part .inner_left_part {
    gap: 10px;
  }
}

.my_page_transaction_header {
  font-size: 24px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  line-height: 0.69;
  letter-spacing: 0.6px;
  padding: 12px 0px;
}

@media (max-width: 767px) {
  .my_page_transaction_header {
    font-size: 17px;
    font-family: "NotoSansKR-Medium";
    font-stretch: normal;
    font-style: normal;
    line-height: 0.69;
    letter-spacing: 0.6px;
    padding: 12px 0px;
  }
}

.information-hr {
  border: 0;
  height: 2px;
  background: #ccc;
  margin-bottom: 56px;
  margin-top: 20px;
}

.information-title-sell {
  color: var(--information-type);
  font-size: 26px;
  font-family: "NotoSansKR-Medium";
  margin-bottom: 24px;
}

.information-section {
  height: 300px;
  background-color: #f5f5f5;
  border: 1px solid #eaeaea;
  margin-bottom: 46px;
  padding: 30px 0px 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5%;
}

@media (max-width: 900px) {
  .information-section {
    flex-direction: column;
    height: auto;
  }

  .information-section .icons-part {
    margin-bottom: 30px;
  }
}

.information-section .icons-part {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.information-section .icons-part .number {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  width: 33px;
  font-size: 12px;
  padding: 2px 14px 2px 14px;
  margin-bottom: 12px;
}

.information-section .icons-part .icon-title {
  padding-top: 16px;
  color: #000;
  opacity: 0.9;
  font-size: 18px;
  font-family: "NotoSansKR-Medium";
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -1.35px;
}

.information-section .icons-part .icon-contents {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.information-section .icons-part .icon-title.active {
  color: var(--black);
}

.information-section .icons-part .icons {
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
}

.information-section .icons-part .icons.w-102px {
  width: 102px;
  height: 102px;
}

.top-border {
  border-top: 1px solid var(--gray) !important;
  margin: 18px 0px;
}

/* 신고서조회 mobile */
.report-section {
  height: auto;
  padding: 30px 0px 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.report-m-button Button {
  font-size: 14px;
  letter-spacing: 0.6px;
  font-weight: bold; 
  font-stretch: normal;
  font-style: normal;
}

.report-d-button Button {
  font-size: 18px;
  letter-spacing: 0.6px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

/* ------자료실(archive)의 테이블------- */

.list-container {
  margin: 0;
  padding: 0;
}

.responsive-list {
  list-style: none;
  padding-left: 0;
}

.border-top-2px {
  border-top: 2px solid #ccc;
}

.list-item {
  border-bottom: 1px solid #ccc;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  flex-basis: 10%;
  text-align: left;
}

.text {
  flex-basis: 70%;
  text-align: left;
}

.icon {
  flex-basis: 5%;
}

@media only screen and (max-width: 480px) {

  /* 모바일 화면에서의 스타일 조정 */
  .list-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .date,
  .text,
  .icon {
    flex-basis: 100%;
  }

  .date {
    margin-bottom: 5px;
  }

  .icon {
    margin-top: 5px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 10px;
}

/* 페이지네이션*/

.pagination button {
  /* width: 40px;  */
  border: none;
  color: black;
  font-size: 1rem;
  background-color: transparent;
  border-radius: 100px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* 텍스트가 여러 줄로 나뉘지 않도록 설정 */
}

.pagination button.active {
  color: black;
  /* 현재 페이지 버튼의 색상 변경 */
}
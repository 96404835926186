#root {
    overflow: hidden;
}

#carousel_main.carousel.slide {
    width: 100%;
    max-width: 1200px;
    height: 400px;
    /* margin-top: -105px; */
    z-index: 0;
    margin: 0 auto;
}


#carousel_main.carousel.slide .carousel-inner {
    width: 100%;
    height: fit-content;
}

#carousel_main.carousel.slide .carousel-item {
    width: 100%;
    height: 400px;
    background-color: #e1e1e1;
}

#carousel_main.carousel.slide .carousel-item.carousel-1 {
    background: url(../../public/img/banner-main-1.png) no-repeat center;
    background-size: cover;
}

#carousel_main.carousel.slide .carousel-item.carousel-2 {
    background: url(../../public/img/banner-main-2.png) no-repeat center;
    background-size: cover;
}

#carousel_main.carousel.slide .carousel-item.carousel-3 {
    background: url(../../public/img/banner-main-3.png) no-repeat center;
    background-size: cover;
}

#carousel_main.carousel.slide .carousel-indicators {
    margin-bottom: 65px;
}

#carousel_main.carousel.slide .carousel-indicators button {
    opacity: 1;
    width: 16px;
    height: 16px;
    border-width: 0;
    background: url(../../public/img/dot.png) no-repeat center;
    background-size: cover;
    margin: 0 5px;
}

#carousel_main.carousel.slide .carousel-indicators button.active {
    opacity: 1;
    width: 16px;
    height: 16px;
    border-width: 0;
    background: url(../../public/img/dot-active.png) no-repeat center;
    background-size: cover;
}

#carousel_main.carousel.slide .carousel-control-prev,
#carousel_main.carousel.slide .carousel-control-next {
    display: none;
}

.container-outer-section1 {
    margin-top: -44px;
    padding: 0;
    max-width: 1080px;
}

.card-banner {
    width: 100%;
    max-width: 1080px;
    height: 90px;
    box-shadow: 5px 8.7px 15px 0 rgba(45, 70, 98, 0.05);
    margin: 0 auto;
    border-radius: 15px;
    border: unset;
}

.card-banner .card-body {
    /* padding: 23px 45px 23px 50px; */
    padding: 23px 25px 23px 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.card-banner h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 146px; */
    width: 125px;
    /* height: 40px; */
    height: 38px;
    background-color: #F9DA03;
    color: rgba(0, 0, 0, 0.9);
    letter-spacing: -0.9px;
    font-size: 16px;
    font-family: 'NotoSansKR-Medium';
    text-align: center;
    margin: 0px;
    border-radius: 30px;
}

.card-banner .card-body ul {
    /* width: 82%; */
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: flex-start;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.card-banner .card-body ul li:first-child {}

.card-banner .card-body ul li {
    display: flex;
    align-items: center;
    color: #838383;
    letter-spacing: -0.8px;
    font-size: 16px;
    font-family: 'NotoSansKR-Medium';
}

.card-banner .card-body ul li label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 32px;
    background-color: #EBEBEB;
    color: #000;
    letter-spacing: -0.8px;
    font-size: 16px;
    font-family: 'NotoSansKR-Medium';
    /* margin-right: 12px; */
    margin-right: 0px;
    margin-left: 20px;
    border-radius: 5px;
    /* row-gap: 3; */
}

.card-banner .card-body ul li span.lbl-red {
    color: #f25f72;
}

.card-banner .card-body ul li span.lbl-orange {
    color: #fe7e00;
}

.card-banner .card-body ul li span.lbl-blue {
    color: #5f9ee5;
}

.card-banner .card-body ul li span.lbl-purple {
    color: #c36ceb;
}

.card-banner .card-body ul li span.lbl-green {
    color: #59b00b;
}

.card-banner .card-body ul li span.amount {
    font-size: 17px;
    font-family: 'NotoSansKR-Medium';
    letter-spacing: -0.9px;
    margin-right: 3px;
    margin-left: 7px;
    /* padding-bottom: 5px; */
    padding-bottom: 0px;
}

.card-banner .card-body ul li span.unit {
    /* padding-bottom: 5px; */
    padding-bottom: 0px;
    font-size: 15px;
}

.section1_wrapper {
    width: 100%;
    height: fit-content;
    min-height: 653px;
    background-color: #F5F5F5;
}

.container-section1 {
    width: 100%;
    max-width: 1200px;
    border: 0 solid red;
    min-height: 466px;
    height: fit-content;
    padding: 0 0 50px 0;
    margin-top: 40px;
}

.container-section1 .g-5,
.container-section1 .gx-5 {
    --bs-gutter-x: 40px;
}

.container-section1 h2 {
    font-size: 26px;
    font-family: 'NotoSansKR-Medium';
    letter-spacing: -1.95px;
    margin-bottom: 18px;
    width: 100%;
    max-width: 520px;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
}

.container-section1 h2.blue {
    color: #1b6fcf;
}

.container-section1 h2.red {
    color: #e9475e;
}

.card-section1 {
    width: 100%;
    max-width: 520px;
    min-width: 360px;
    height: 422px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    margin: 0 auto;
}

.card-section1 .card-body {
    padding: 30px;
}

.red-container {
    border-radius: 15px;
    background-color: #FFF5F6;
}

.blue-container {
    border-radius: 15px;
    background-color: #F5F9FF;
}

.container-1 {
    width: 100%;
    height: 195px;
    margin-bottom: 20px;
    padding: 0 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-1>div {
    height: 195px;
}

.container-1>div>img,
.container-2>div>img {
    width: 100%;
}

.container-1>div:first-child {
    width: 60%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.container-2>div:last-child {
    width: 70%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.container-1>div:last-child {
    width: 40%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.container-1>div:last-child img {
    /* margin-top: 40px; */
}

.container-1 h3,
.container-2 h3 {
    width: 100%;
    color: #000;
    font-size: 18px;
    font-family: 'NotoSansKR-Medium';
    letter-spacing: -0.9px;
    margin-bottom: 24px;
    /* margin-top: 45px; */
}

.container-2 h3 {
    margin-top: 5px;
    margin-bottom: 10px;
}

.blue-container.container-1 {
    padding-right: 28px;
}

.blue-container.container-1 h3 span,
.blue-container.container-2 h3 span {
    font-size: 18px;
    font-family: 'NotoSansKR-Medium';
    color: #1b6fcf;
}

.red-container.container-1 h3 span,
.red-container.container-2 h3 span {
    font-size: 18px;
    font-family: 'NotoSansKR-Medium';
    color: #e9475e;
}

.container-1 a,
.container-2 a {
    font-size: 15px;
    font-family: 'NotoSansKR-Medium';
    letter-spacing: -0.75px;
}

.blue-container.container-1 a,
.blue-container.container-1 a:hover,
.blue-container.container-1 a:focus,
.blue-container.container-1 a:active,
.blue-container.container-1 a:visited,
.blue-container.container-2 a,
.blue-container.container-2 a:hover,
.blue-container.container-2 a:focus,
.blue-container.container-2 a:active,
.blue-container.container-2 a:visited {
    color: #1b6fcf;
    border-bottom: 1px solid #1b6fcf;
    padding-bottom: 5px;
    cursor: pointer;
}

.red-container.container-1 {
    padding-right: 52px;
    padding-top: 3px;
}

.red-container.container-1 a,
.red-container.container-1 a:hover,
.red-container.container-1 a:focus,
.red-container.container-1 a:active,
.red-container.container-1 a:visited,
.red-container.container-2 a,
.red-container.container-2 a:hover,
.red-container.container-2 a:focus,
.red-container.container-2 a:active,
.red-container.container-2 a:visited {
    color: #e9475e;
    border-bottom: 1px solid #e9475e;
    padding-bottom: 5px;
    cursor: pointer;
}

.container-2 {
    width: 100%;
    height: 145px;
    padding: 0 40px;
}

.text-blue {
    color: #1b6fcf;
}

.text-red {
    color: #e9475e;
}

.container-2 {
    width: 100%;
    height: 145px;
    margin-bottom: 20px;
    padding: 0 0 0 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section2_wrapper {
    width: 100%;
    height: fit-content;
    min-height: 596px;
    background-color: #F9EBC5;
    padding: 60px 0;
}

.container-section2 {
    width: 100%;
    max-width: 1080px;
    padding: 0;
}

.section2_heading {
    display: block;
    text-align: center;
    color: #000;
    font-size: 32px;
    font-family: 'NotoSansKR-SemiBold';
    letter-spacing: -1.6px;
    margin-bottom: 10px;
}

.section2_heading span {
    font-size: 32px;
    font-family: 'NotoSansKR-SemiBold';
    color: #ff7200;
}

.section2_caption {
    color: #000;
    font-size: 20px;
    font-family: 'NotoSansKR-Medium';
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 35px;
}

.sec2-row {
    --bs-gutter-x: 28px;
    --bs-gutter-y: 15px;
}

.card-sec2 {
    width: 100%;
    max-width: 250px;
    height: 350px;
    border: unset;
    border-radius: 20px;
    margin: 0 auto;
}

.card-sec2 .card-body {
    padding: 38px 13px;
}

.card-sec2 .card-body .img2 {
    display: block;
    margin: 15px 0 0 18px;
}

.card-sec2 .card-body .img3 {
    display: block;
    margin: 10px 0 0px 17px;
}

.card-sec2 .card-body .img4 {
    display: block;
    margin: 10px 0 0px 19px;
}

.sec2-card-heading {
    color: #000;
    font-size: 20px;
    font-family: 'NotoSansKR-Medium';
    letter-spacing: -1px;
    text-align: center;
    margin-top: 40px;
}

.sec2-card-heading.last {
    margin-top: 35px;
}

.section3_wrapper {
    width: 100%;
    height: fit-content;
    /* min-height: 650px; */
    background-color: #FFCE00;
    padding: 63px 0 80px 0;
}

.container-section3 {
    width: 100%;
    max-width: 1080px;
    padding: 0;
}

.section3_heading {
    display: block;
    text-align: center;
    color: #000;
    font-size: 32px;
    font-family: 'NotoSansKR-SemiBold';
    letter-spacing: -1.6px;
    margin-bottom: 45px;
}

.section3_heading span {
    font-size: 32px;
    font-family: 'NotoSansKR-SemiBold';
    color: #ff7200;
}

.section3_mainbanner {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 1080px;
}

.pb-90px {
    padding-bottom: 90px;
}

.carousel-caption-wrapper-1 {
    margin-top: 85px;
    margin-left: 11.6%;
}

.carousel-caption-wrapper-1 h3 {
    color: #fff;
    font-size: 32px;
    line-height: 50px;
    font-family: 'NotoSansKR-SemiBold';
    letter-spacing: -1.6px;
    margin-bottom: 26px;
}

.carousel-caption-wrapper-1 h3 span {
    font-size: 40px;
    font-family: 'NotoSansKR-SemiBold';
    color: #f9d908;
    letter-spacing: -1.6px;
    line-height: 46px;
    margin-left: 7px;
}

.carousel-caption-wrapper-1 h4 {
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    font-family: 'NotoSansKR-SemiBold';
    letter-spacing: -1px;
    margin-left: 5px;
}

.carousel-caption-wrapper-2 {
    margin-top: 95px;
    margin-left: 31.2%;
}

.carousel-caption-wrapper-2 h3 {
    color: #000;
    font-size: 32px;
    line-height: 50px;
    font-family: 'NotoSansKR-SemiBold';
    letter-spacing: -1.6px;
    margin-bottom: 0;
    margin-top: -6px;
    margin-left: -3px;
}

.carousel-caption-wrapper-2 h3 span {
    font-size: 40px;
    font-family: 'NotoSansKR-SemiBold';
    color: #000;
    letter-spacing: -1.6px;
    line-height: 46px;
    margin-left: 7px;
}

.carousel-caption-wrapper-2 h4 {
    color: #000;
    font-size: 22px;
    line-height: 50px;
    font-family: 'NotoSansKR-SemiBold';
    letter-spacing: -1.1px;
    margin-bottom: 0;
}

.carousel-caption-wrapper-3 {
    margin-top: 107px;
    margin-left: 11.6%;
}

.carousel-caption-wrapper-3 h5 {
    color: #000;
    font-size: 22px;
    line-height: 22px;
    font-family: 'NotoSansKR-SemiBold';
    letter-spacing: -1.1px;
    margin-bottom: 10px;
}

.carousel-caption-wrapper-3 h4 {
    color: #000;
    font-size: 32px;
    line-height: 50px;
    font-family: 'NotoSansKR-SemiBold';
    letter-spacing: -1.6px;
    margin-bottom: 24px;
}

.carousel-caption-wrapper-3 h4 span {
    font-size: 40px;
    font-family: 'NotoSansKR-SemiBold';
    color: #000;
    letter-spacing: -1.6px;
    line-height: 46px;
    margin-left: 7px;
}

.carousel-caption-wrapper-3 h3 {
    color: #000;
    font-size: 18px;
    line-height: 26px;
    font-family: 'NotoSansKR-SemiBold';
    letter-spacing: -1px;
    margin-left: 0;
}

@media (max-width:1080px) {
    .carousel-caption-wrapper-3 h5 {
        font-size: 18px;
        margin: 0;
    }

    .carousel-caption-wrapper-3 h4 {
        font-size: 27px;
        line-height: 27px;
        margin-bottom: 14px;
    }

    .carousel-caption-wrapper-3 h4 span {
        font-size: 34px;
    }

    .carousel-caption-wrapper-3 h3 {
        font-size: 16px;
        line-height: 24px;
    }

    .carousel-caption-wrapper-2 h4 {
        font-size: 18px;
    }

    .carousel-caption-wrapper-2 h3 {
        font-size: 27px;
    }

    .carousel-caption-wrapper-2 h3 span {
        font-size: 34px;
    }

    .carousel-caption-wrapper-1 h3 {
        font-size: 25px;
        line-height: 28px;
        margin-bottom: 7px;
    }

    .carousel-caption-wrapper-1 h3 span {
        font-size: 30px;
    }

    .carousel-caption-wrapper-1 h4 {
        font-size: 16px;
    }

    .carousel-caption-wrapper-2 {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: 77px;
    }

    .carousel-caption-wrapper-2 h3 {
        margin-top: -8px;
        line-height: 42px;
    }

    .carousel-caption-wrapper-2 h3 span {
        display: block;
        margin: 0;
    }

    .container-section1 h2 {
        margin-top: 0;
    }

    .pb-sm-0 {
        padding-bottom: 0 !important;
    }

    .card-section1 {
        /* min-width: unset; */
    }

    .section2_heading,
    .section2_heading span {
        font-size: 16px;
    }

    .section2_caption {
        font-size: 13px;
    }

    .sec2-card-heading {
        font-size: 14px;
    }

    .container-section1 h2 {
        font-size: 16px;
    }

    .section3_heading,
    .section3_heading span {
        font-size: 16px;
    }

    .container-2>div>img {
        width: calc(100% - 10px);
    }

    .container-1 h3,
    .container-1 h3 span,
    .container-2 h3,
    .container-2 h3 span {
        font-size: 13px !important;
    }

    .container-1 h3 {
        /* margin-top: 60px; */
    }

    .container-1>div>img {
        /* margin-top: 60px; */
    }

    .red-container.container-1 a,
    .red-container.container-1 a:hover,
    .red-container.container-1 a:focus,
    .red-container.container-1 a:active,
    .red-container.container-1 a:visited,
    .red-container.container-2 a,
    .red-container.container-2 a:hover,
    .red-container.container-2 a:focus,
    .red-container.container-2 a:active,
    .red-container.container-2 a:visited,
    .blue-container.container-1 a,
    .blue-container.container-1 a:hover,
    .blue-container.container-1 a:focus,
    .blue-container.container-1 a:active,
    .blue-container.container-1 a:visited,
    .blue-container.container-2 a,
    .blue-container.container-2 a:hover,
    .blue-container.container-2 a:focus,
    .blue-container.container-2 a:active,
    .blue-container.container-2 a:visited {
        font-size: 12px;
    }

    .container-1 {
        padding: 0 20px 0 20px !important;
    }

    .container-2 {
        padding: 0 0 0 25px !important;
    }

    .container-section1 .g-5,
    .container-section1 .gx-5 {
        --bs-gutter-x: 10px;
    }
}

@media (max-width:800px) {
    .container-section1 h2.red {
        margin-top: 30px;
    }
}

/* @media (max-width:991.98px) { */
@media (max-width:1133px) {
    .card-banner {
        height: fit-content;
    }

    .card-banner .card-body {
        padding: 20px 20px 20px 20px;
    }

    .card-banner .card-body ul {
        width: calc(100% - 20px);
        padding-left: 15px;
        height: 75px;
    }
}

@media screen and (max-width:767px) {
    .carousel-caption-wrapper-1 {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: 47px;
    }

    .carousel-caption-wrapper-3 {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
    }

    #carousel_main.carousel.slide .carousel-item {
        height: 440px;
        margin-top: 56px;
    }

    .container-outer-section1 {
        margin-top: 34px;
    }

    #carousel_main.carousel.slide .carousel-item.carousel-1 {
        background: url(../../public/img/banner-main-1-mobile.png) no-repeat center;
        background-size: cover;
    }

    #carousel_main.carousel.slide .carousel-item.carousel-2 {
        background: url(../../public/img/banner-main-2-mobile.png) no-repeat center;
        background-size: cover;
    }

    #carousel_main.carousel.slide .carousel-item.carousel-3 {
        background: url(../../public/img/banner-main-3-mobile.png) no-repeat center;
        background-size: cover;
    }

    .card-banner h1 {
        font-size: 13px;
    }

    .card-banner .card-body ul li label {
        font-size: 13px;
    }

    .card-banner .card-body ul li span.amount {
        font-size: 13px;
    }
}

@media screen and (max-width:512px) {
    .card-banner .card-body ul {
        height: 145px;
    }

    .card-section1 {
        /* min-width: unset; */
    }
}

.selling-success {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    padding: 20px 0;
}

.selling-success>div:first-child {
    color: #FF9C00;
}

.selling-success>div:last-child {
    color: #bababa;
}

.selling-success>div:last-child>span {
    color: #000;
}

.headerFixedLayout {
    margin-top: 112px;
}

@media screen and (max-width: 768px) {
    .headerFixedLayout {
        margin-top: 0;
    }
}
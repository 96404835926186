html {
  --background-color-sell: #1E191A;
  --background-color-buy: #1E191A;
  --inground-color: #FFDE00
}

.mobile-intro-wrapper {
  min-height: 725px;
  height: 95vh;
  display: flex;
  flex-direction: column;
}

.mobile-intro-wrapper .header {
  padding: 47px 20px 0 20px;
  margin-top: 56px;
}

.mobile-intro-wrapper .header .header-text {
  font-family: 'NotoSansKR-Bold';
  font-size: 24px;
}

.mobile-intro-wrapper .header .text-24 {
  font-family: 'NotoSansKR-Bold';
  font-size: 30px;
}

.mobile-intro-wrapper .section {
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.1);
  height: 67px;
  display: flex;
  align-items: center;
  margin-top: 37px;
  padding: 0 20px;
}

@media screen and (max-width: 320px) {
  .mobile-intro-wrapper .section {
    padding: 0 10px;
  }
}


.mobile-intro-wrapper .section .price-box {
  background-color: #F9DA02;
  padding: 9px 20px;
  font-size: 15px;
  border-radius: 50px;
  font-family: 'NotoSansKR-Medium';
  /* min-width: 115px; */
  min-width: 120px;
  white-space: nowrap;
}


.mobile-intro-wrapper .section .gray-box {
  background-color: #EBEBEB;
  padding: 7px 12px;
  font-size: 15px;
  font-family: 'NotoSansKR-Medium';
  /* margin-left: 21px; */
  margin-left: 24px;
  border-radius: 10px;
  white-space: nowrap
}

.mobile-intro-wrapper .section .price {
  color: #fe7e00;
  font-family: 'NotoSansKR-Medium';
  font-size: 18px;
  margin-left: 13px;
}

.mobile-intro-wrapper .section .price-unit {
  color: #838383;
  font-family: 'NotoSansKR-Medium';
  font-size: 16px;
  margin-left: 4px;
  white-space: nowrap;
}




.mobile-intro-wrapper .figure-box {
  position: relative;
  margin-top: 64px;
}


.mobile-intro-wrapper .figure {
  position: absolute;
  /* right: 0; */
  right: -81px;
  /* 초기 위치 */
  transition: right 1.5s ease;
  /* right 속성에 대한 변화를 1초 동안 서서히 적용 */

}

.mobile-intro-wrapper .figure-180 {
  position: absolute;
  left: -81px;
  transition: left 1.5s ease;
  /* right 속성에 대한 변화를 1초 동안 서서히 적용 */
  transform: rotateX(180deg) rotateY(180deg);

}



@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}



.mobile-intro-wrapper .rectangle-sell {
  width: 0px;
  height: 0px;
  position: relative;
  border-left: 200px solid transparent;
  border-right: 50px solid var(--background-color-sell);
  border-bottom: 281px solid var(--background-color-sell);

}

.mobile-intro-wrapper .rectangle-buy {
  width: 0px;
  height: 0px;
  position: relative;
  border-left: 200px solid transparent;
  border-right: 50px solid var(--background-color-buy);
  border-bottom: 281px solid var(--background-color-buy);

}


.mobile-intro-wrapper .triangle {
  width: 0px;
  height: 0px;
  position: absolute;
  bottom: -231px;
  right: -51px;
  border-bottom: 231px solid var(--inground-color);
  border-left: 160px solid transparent;
  border-right: 0px solid transparent;

}


.mobile-intro-wrapper .inside {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 120px solid transparent;
  border-right: 170px solid #FFDE00;
  border-bottom: 211px solid #FFDE00;
  top: 20px;
  left: -120px;
}


.sell-text {
  position: absolute;
  top: 10vh;
  left: 20px;
  color: var(--color);
  z-index: 1;
  font-size: 24px;
  transition: all 2s ease;
  /* right 속성에 대한 변화를 1초 동안 서서히 적용 */
  opacity: var(--opacity);
}

.buy-text {
  position: absolute;
  top: 20vh;
  right: 20px;
  color: var(--color);
  z-index: 1;
  font-size: 24px;
  transition: all 2s ease;
  /* right 속성에 대한 변화를 1초 동안 서서히 적용 */
  opacity: var(--opacity);
}

.mobile-intro-wrapper .footer {
  display: flex;
  justify-content: center;
  font-size: 11px;
  color: #666666;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 25px;
}

.mobile-intro-wrapper .footer .phone-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 16px;
}

.mobile-intro-wrapper .footer .phone-box .phone-text {
  font-size: 14px;
}


.mobile-intro-wrapper .footer .phone-box .phone-text .phone {
  font-weight: bold;
  font-size: 22px;
  color: black;
}

.mobile-intro-wrapper .footer .phone-box .phone-text .phone a {
  cursor: pointer;
}

.mobile-intro-wrapper .footer .phone-box .footer-phone-img {
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
}

.mobile-intro-wrapper .footer .phone-box .footer-phone-img img {
  position: absolute
}

.mobile-intro-img-sell {
  width: 75vw !important;
  height: 33vh !important;
  position: absolute;
  /* left: -100px; */
  left: var(--left-x);
  transition: left 1.1s ease;
  /* right 속성에 대한 변화를 1초 동안 서서히 적용 */
  opacity: var(--opacity);
}


.mobile-intro-img-sell-2 {
  width: 75vw !important;
  height: 33vh !important;
  position: absolute;
  /* left: -100px; */
  left: -1px;
  opacity: var(--opacity);
  transition: all 1.2s ease;
}

.mobile-intro-img-sell-3 {
  width: 75vw !important;
  height: 33vh !important;
  position: absolute;
  /* left: -100px; */
  left: -1px;
  opacity: var(--opacity);
  transition: all 1.2s ease;
}


.mobile-intro-img-buy {
  width: 75vw !important;
  height: 33vh !important;
  position: absolute;
  /* right: -100px; */
  right: var(--right-x);
  transition: right 1.1s ease;
  /* right 속성에 대한 변화를 1초 동안 서서히 적용 */
}


.mobile-intro-img-buy-2 {
  width: 75vw !important;
  height: 33vh !important;
  position: absolute;
  /* right: -100px; */
  right: 0;
  opacity: var(--opacity);
  transition: all 1.2s ease;
}


.mobile-intro-img-buy-3 {
  width: 75vw !important;
  height: 33vh !important;
  position: absolute;
  /* right: -100px; */
  right: 0;
  opacity: var(--opacity);
  transition: all 1.2s;
}


.mobile-intro-img-wrapper {
  position: relative;
  margin-top: 64px;
}

.amount {
  font-family: 'NotoSansKR-Medium';
  font-size: 18px;
  /* margin-left: 13px; */
  margin-left: 10px;
}

.lbl-red {
  color: #f25f72;
}

.lbl-orange {
  color: #fe7e00;
}

.lbl-blue {
  color: #5f9ee5;
}

.lbl-purple {
  color: #c36ceb;
}

.lbl-green {
  color: #59b00b;
}

.carousel-indicators {
  display: none;
}


.copy-right {
  text-align: center;
  font-size: 10px;
  margin-top: 17px;
}
